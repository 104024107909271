import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, FormControl } from '@angular/forms';
import { StepperComponent } from '@components/planner/form/stepper/stepper.component';
import { CustomerActivityService } from '@services/planner/customer-activity.service';
import { UserService } from '@services/planner/user.service';
import { QuestionService } from '@services/planner/question.service';
import { QuestionResponse, RebaDataService } from '@services/planner/reba-data.service';
import { buffer, take } from 'rxjs/operators';
import { AbstractQuestion } from 'src/app/typings/questions/AbstractQuestion';
import { Condition } from 'src/app/typings/Screening';
import { environment } from 'src/environments/environment';
import { ToolsPrimaryConfigService } from '@services/planner/tools-primary-config.service';
import { QuestionComponent } from '@components/planner/form/question/question.component';
import { TranslatableString } from 'src/app/typings/TranslatableString';
import { interval, Subject } from 'rxjs';

@Component({
  selector: 'app-screening',
  templateUrl: './screening.component.html',
  styleUrls: ['./screening.component.scss']
})
export class ScreeningComponent implements OnInit, AfterViewChecked, AfterViewInit {

  @ViewChild('stepper') stepper : StepperComponent

  @ViewChildren(QuestionComponent) questions: QueryList<QuestionComponent>;

  @Input() screening: any;
  @Input() isVerticalScreening: boolean = false;
  @Input() backCTA: any;
  @Input() submitText: string;
  @Input() hideSubmit: boolean;
  @Input() onlySaveOnSubmit: boolean = false;

  @Input() redirect: boolean;
  @Output() onScreeningSubmit = new EventEmitter<any>();

  public screeningType: 'program' | 'comprehensive' | 'category'
  public screeningForm: FormGroup;

  public sections: {[section: string] : {name: TranslatableString, groups: {[group: string] : AbstractQuestion<any>[]}, description: TranslatableString}};
  public sectionInvalid: boolean;

  public readInformation: boolean;
  public singleQuestions: AbstractQuestion<any>[];

  public kontent: any;
  public isDataLoaded: boolean = false;
  public isCompleted: boolean = false;

  public state: string = 'va';
  public screeningCode: string;
  public updateGroupTitles: boolean;
  public zipCode: string;
  private receivedQuestions:  Map<string,string> = new Map();
  private viewedQuestions: Map<string,string> = new Map();
  private throttler: Subject<any> = new Subject<any>();
  editMode:boolean = false; // edit mode for screening using query param

  constructor(
    private fb : FormBuilder,
    private toolsConfig: ToolsPrimaryConfigService,
    private questionService: QuestionService,
    private api: RebaDataService,
    private customerActivityService: CustomerActivityService,
    private userService: UserService,
  ) {
    this.sections = {};
    this.redirect = true;
    this.singleQuestions = [];
    this.screeningType = 'comprehensive';
    this.sectionInvalid = false;
    this.hideSubmit = false;
    this.updateGroupTitles = false;
    this.readInformation = false;

    // buffer all emissions over a period, only push last values to CAD
    this.throttler
    .pipe(buffer((interval(250))))
    .subscribe(async(val) =>{
      const lastVal = val[val.length-1]
      if(lastVal){
        await this.customerActivityService.updateActivity(lastVal).toPromise()}
    });
  
  }

  public unsorted(a: any, b: any) { return 0 }

  log(screeningForm: any, section, questionGroup){
    console.log('LOGGER', screeningForm)
    console.log(section, questionGroup)
    console.log('LOGGER', event)
    return ' ok'
  }
  async ngOnInit() {

    const urlParams = new URLSearchParams(window.location.search);
    this.editMode = urlParams.get('edit') === 'true' ? true : false;
    if(this.editMode){
      this.updateGroupTitles = true;
    }

    this.kontent = {};
    this.screeningCode = this.screening.code;

    // if edit mode, treat as incomplete
    this.isCompleted = this.editMode ? false : JSON.parse(localStorage.getItem('screeningAnswers') ?? '{}')[this.screeningCode]?.completed ?? false;
  
    if (this.userService.authSet()) {
      const localChangedBudget = localStorage.getItem("changed_budget");
      const screeningsCompleted = this.toolsConfig.customerActivity.basic.completedScreenings;
      if (screeningsCompleted.includes(this.screeningCode) && localChangedBudget !== "true") {
        this.isCompleted = this.editMode ? false : true;;
      } else if (localStorage.getItem("changed_budget") == "true") {
        this.isCompleted = false;
        localStorage.removeItem("changed_budget");
      }
    }
    
    this.screeningForm = this.screeningToFormGroup();
    this.screeningForm.valueChanges.subscribe(this.onFormChange.bind(this));

    await this.populateAnswers(); // To Populate Initial Question's Answers and Evaluate Conditions.

    this.evaluateConditions('');

    await this.populateAnswers(); // To Populate Conditional Question's Answers.

    this.api.respondToScreening(this.screening.code, environment.workflowStep, 'va', {}, 'IN_PROGRESS', this.screeningType).pipe(take(1))
    .toPromise().then((res: any) => {
      // sent
      this.isDataLoaded = true;
    });


  }
  //must check frequently for newly received questions
  ngAfterViewChecked() {
    const visibleQuestions = this.questions.filter(question => {
      const rect = question.questionDiv.nativeElement.getBoundingClientRect()
      return (rect.width >0 && rect.height > 0)
    });

    // questions with dimensions are received
    visibleQuestions.forEach(question=>{
      this.addToList(question.question.key,'received')
    })
  }


  ngAfterViewInit(): void {
    const options = {
      root: null, // default to viewport
      threshold: 1 // 100% of element must be in viewport
    };

    const observer = new IntersectionObserver((result) => {
      result.forEach(item => {
        // item has dimensions (not hidden) and is intersecting viewport
        if (item.boundingClientRect.height > 0 && item.boundingClientRect.width > 0 && item.isIntersecting) {
          this.addToList(item.target.id, 'viewed')
        }
      })
    }, options);

    this.questions.changes.subscribe(() => {
      this.questions.forEach(question => {
        observer.observe(question.questionDiv.nativeElement)
      });
    })
  }

  // Add unique items to map
  addToList(element: string, status: string) {

    switch (status) {
      case 'viewed':
        const viewedLength = this.viewedQuestions.size
        this.viewedQuestions.set(element, status);
        //number of viewed questions changes, add to CAD
        if (this.viewedQuestions.size > viewedLength) {
          this.pushQuestionDataToCAD()
        }
        break;
      case 'received':
        const receivedLength = this.receivedQuestions.size
        this.receivedQuestions.set(element, status);
        //number of received questions changes, add to CAD
        if (this.receivedQuestions.size > receivedLength) {
          this.pushQuestionDataToCAD()
        }
        break;
      default:
        return;

    }
  }

  async pushQuestionDataToCAD() {
    const qsData = {};
    qsData['received'] = {};
    this.receivedQuestions.forEach((value,key)=>{
      qsData['received'][key] = value
    })
    qsData['viewed'] = {};
     this.viewedQuestions.forEach((value,key)=>{
      qsData['viewed'][key] = value
    })
    const questionData = { 'qsData': qsData };
    if (this.receivedQuestions.size > 0 || this.viewedQuestions.size > 0 )
      await this.throttler.next(questionData)

  }


  private cacheAnswers(completed: boolean = false){

    const cache = JSON.parse(localStorage.getItem('screeningAnswers') ?? '{}');
    const screeningsCompleted = this.toolsConfig.customerActivity.basic.completedScreenings;

    if((this.screening.code in cache && cache?.[this.screening.code]?.completed) ||
    (screeningsCompleted.includes(this.screeningCode))){
      completed = true;
    }

    localStorage.setItem('screeningAnswers', JSON.stringify(Object.assign(cache, {[this.screening.code]: {completed}})));

    // Fetch current ZipCode and Store to AllAnswers in LocalStorage
    let currentZipCode = '';
    if(localStorage.getItem('zipCode')){
      currentZipCode = localStorage.getItem('zipCode') === '' ? this.zipCode : localStorage.getItem('zipCode');
    } else {
      currentZipCode = this.zipCode;
    }

    // Store All CADId's Answers at one place
    const allAnswers = JSON.parse(localStorage.getItem('allAnswers') ?? '{}');
    let CADId = this.userService.CADId;
    let currentCADIdAnswers = CADId in allAnswers ? allAnswers[CADId] : {};
    currentCADIdAnswers = Object.assign(currentCADIdAnswers, {...this.getAnswers(), 'zipcode': currentZipCode})
    localStorage.setItem('allAnswers', JSON.stringify(Object.assign(allAnswers, {[CADId]: currentCADIdAnswers})));
  }

  async onSubmit(){
    window.scrollTo(0,0)

    if(this.screeningForm.invalid){
      this.sectionInvalid = true;
      this.screeningForm.markAllAsTouched();
      return;
    }

    this.isDataLoaded = false;

    try {


      const lastSection = Object.keys(this.sections) as any[];
      const lastSectionGroup = this.screeningForm.get(lastSection[lastSection.length - 1]);
      await this.sendQuestionResponses(lastSectionGroup);


      await this.api.respondToScreening(this.screening.code, environment.workflowStep, this.state, [], 'COMPLETED', this.screeningType).pipe(take(1)).toPromise();

      this.cacheAnswers(true);

      if(this.redirect){
        window.location.href = this.toolsConfig.routes.find((route) => (
          route.assessmentCode === this.screeningCode &&  (route.type === 'vertical-plan' || route.type === 'vertical-subtopic-plan')
        ))?.route
      }

      this.onScreeningSubmit.emit(this.screeningForm.getRawValue())

    } catch(err){
      console.error("Failed to send screening.", err)
      // show error modal / dialog
    }
  }


  private getAnswers(){
    const rawFormValue = this.screeningForm.getRawValue();

    if(this.screeningType === 'comprehensive'){
      const answers: any = {}

      for(const group of Object.values(rawFormValue)){
        for(const ans of Object.values(group)){
            Object.assign(answers, ans)
        }
      }

      return answers;
    }

    return rawFormValue;
  }


  sendQuestionResponses(sectionGroup: AbstractControl) {
    let responses: QuestionResponse[] = [];
    Object.keys(sectionGroup.value).forEach(group => {

      Object.keys(sectionGroup.value[group]).forEach(q => {
        if (sectionGroup.value[group][q] != undefined) {
          responses.push({
            questionId: q,
            questionVersion: environment.workflowStep,
            answer: {
              text: q,
              value: sectionGroup.value[group][q]
            }
          });
        }
      });
    });

    return this.api.respondToQuestions(this.screening.code, environment.workflowStep, responses).pipe(take(1))
    .toPromise()
  }

  onNextSection(section: any){

    window.scrollTo(0,0)
    const sectionGroup = this.screeningForm.get(section);

    if(sectionGroup.invalid){
      sectionGroup.markAllAsTouched();
      this.sectionInvalid = true;
      return;
    }

    if(!this.onlySaveOnSubmit){
      this.cacheAnswers();
    }
  

    this.sendQuestionResponses(sectionGroup);
    this.sectionInvalid = false;;
    this.stepper.nextStep();
  }


  private onFormChange(changes: any){
    if(!this.onlySaveOnSubmit){
      this.cacheAnswers();
    }
  }

  onGoBack(){
    this.sectionInvalid = false;
    window.scrollTo(0,0)
    this.stepper.previousStep();
  }

  screeningToFormGroup(isCompleted: boolean = false){
    const formGroup = this.fb.group({});

    for(const section of this.screening.sections){

        const sectionGroup = this.fb.group({});
        const sectionName = section.name['en'];
        this.sections[sectionName] = {name: section.name, groups: {}, description: section.description};

        for(const questionGroup of section.questionGroups){

          const questionGrp = this.fb.group({});
          const grpName = questionGroup.name['en'];
          const grpQuestions = []; 

          for(const question of questionGroup.questions){
            const baseQuestion = this.questionService.getQuestion(question);
            questionGrp.addControl(baseQuestion.key, baseQuestion.asFormControl())
            grpQuestions.push(baseQuestion);
          }

          this.sections[sectionName].groups[grpName] = grpQuestions;
          sectionGroup.addControl(grpName, questionGrp);
        }

        formGroup.addControl(sectionName, sectionGroup);
    }

    return formGroup;
  }

  evaluateConditions(value: any) {

    let conditionalQuestions = this.screening.conditionalQuestions || [];
    let CADId = this.userService.CADId || '';

    let userAnswers = Object.assign(this.getScreeningAnswers(), JSON.parse(localStorage.getItem('allAnswers') ?? '{}')[CADId]);




    if(userAnswers[value] === null){
      return
    }

    const booleanComparatorLookup = {
      'EQUAL': ' === ',
      'NOT_EQUAL': ' !== ',
      'GREATER_THAN': ' > ',
      'GREATER_THAN_INCLUSIVE': ' >= ',
      'LESS_THAN': ' < ',
      'LESS_THAN_INCLUSIVE': ' <='
    }

    conditionalQuestions.map((conditionalQues: any) => {

      const baseQuestion = this.questionService.getQuestion(conditionalQues);

      // if(this.screeningType !== 'comprehensive'){
      //   this.singleQuestions = this.singleQuestions.filter((question: any) => question.key !== conditionalQues.id);
      //   this.screeningForm.removeControl(baseQuestion.key);
      // } else {
        
        let rawFormValue = this.screeningForm.getRawValue();
        for(const sectionName of Object.keys(rawFormValue)){
          const sectionFormControl = this.screeningForm["controls"][sectionName];
          const section = rawFormValue[sectionName];
          for(const questionGroupName of Object.keys(section)){
            const questionGroupFormControl = sectionFormControl["controls"][questionGroupName] as FormGroup;
            const questionGroup = section[questionGroupName];
            for(const questionCode of Object.keys(questionGroup)){
              const questionCodeFormControl = questionGroupFormControl["controls"][questionCode];
              if(questionCode === baseQuestion.key && questionCodeFormControl){
                questionCodeFormControl.disable();
              }
            }
          }
        }
      // }

      let conditions: Condition[] = conditionalQues.fullCondition.conditions || [];
      let operator = conditionalQues.fullCondition.operator;
      let conditionsEvaluation:  boolean[] = [];
      let showQuestion: boolean = false;

      conditions.forEach((condition: any) => {

        let value = condition.value;
        let userValue = userAnswers[condition.code]; 

        if(condition.answerType !== 'multiSelect'){

          let comparator = booleanComparatorLookup[condition.comparator];

          if(condition.answerType === 'boolean'){
            userValue = userValue !== null ? String(userValue) : userValue;
          }

          let evalString = '';
          if(userValue){

            if(condition.answerType === 'select' || condition.answerType ==='shortText' || condition.answerType === 'boolean'){
              evalString += `'${userValue.toString().toLowerCase()}'` + comparator +`'${value.toString().toLowerCase()}'`;
            } else if (condition.answerType === 'date'){

              let today = new Date();
              let birthDate = new Date(userValue);
              let age = today.getFullYear() - birthDate.getFullYear();
              let m = today.getMonth() - birthDate.getMonth();
              if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                  age--;
              }
              evalString += age + comparator + value;

            } else {
              evalString += userValue + comparator + value;
            }
            conditionsEvaluation.push(eval(evalString));
          } else {
            conditionsEvaluation.push(false);
          }

        } else {

          let comparator = condition.comparator;

          if(userValue && userValue.length > 0){

            if(comparator === 'CONTAINS_ALL'){
              conditionsEvaluation.push(value.every(r => userValue.includes(r)));
            }

            if(comparator === 'CONTAINS_ANY'){
              conditionsEvaluation.push(value.some(r => userValue.includes(r)));
            }

            if(comparator === 'DOES_NOT_CONTAINS_ALL'){
              conditionsEvaluation.push(!value.every(r => userValue.includes(r)));
            }

            if(comparator === 'DOES_NOT_CONTAINS_ANY'){
              conditionsEvaluation.push(!userValue.some(r => value.includes(r)));
            }

          } else {
            conditionsEvaluation.push(false);
          }
        }

      });



      if(operator === 'AND' && conditionsEvaluation.length > 0 ){
        let every = arr => arr.every(Boolean);
        showQuestion = every(conditionsEvaluation);
      }

      if(operator === 'OR' && conditionsEvaluation.length > 0 ) {
        let some = arr => arr.some(Boolean);
        showQuestion = some(conditionsEvaluation);
      }

     // console.log(showQuestion)

      if(showQuestion){ 

        // if(this.screeningType !== 'comprehensive'){

        //   this.screeningForm.addControl(baseQuestion.key, baseQuestion.asFormControl())
        //   this.singleQuestions.push(baseQuestion);

        //   if(userAnswers[baseQuestion.key] != null){
        //     this.screeningForm.patchValue(userAnswers);
        //   }

        // } else {

          let rawFormValue = this.screeningForm.getRawValue();
          for(const sectionName of Object.keys(rawFormValue)){
            const sectionFormControl = this.screeningForm["controls"][sectionName];
            const section = rawFormValue[sectionName];
            for(const questionGroupName of Object.keys(section)){
              const questionGroupFormControl = sectionFormControl["controls"][questionGroupName] as FormGroup;
              const questionGroup = section[questionGroupName];
              for(const questionCode of Object.keys(questionGroup)){
                const questionCodeFormControl = questionGroupFormControl["controls"][questionCode] as FormControl;
                if(questionCode === baseQuestion.key && questionCodeFormControl){
                  questionCodeFormControl.enable();
                }
              }
            }
          }
        // }
      }
    });
    this.updateGroupTitles = !this.updateGroupTitles;
  }

  async populateAnswers() {
    const rawFormValue = this.screeningForm.getRawValue();
    let CADId = this.userService.CADId || '';
    const allAnswers = JSON.parse(localStorage.getItem('allAnswers') ?? '{}')[CADId] || {};

    for(const sectionName of Object.keys(rawFormValue)){
        const sectionFormControl = this.screeningForm["controls"][sectionName];
        const section = rawFormValue[sectionName];
        for(const questionGroupName of Object.keys(section)){
          const questionGroupFormControl = sectionFormControl["controls"][questionGroupName];
          const questionGroup = section[questionGroupName];
          for(const questionCode of Object.keys(questionGroup)){
            const questionCodeFormControl = questionGroupFormControl["controls"][questionCode];

            // check if answer is present in local storage
            if(questionCode in allAnswers){
              questionCodeFormControl.value = allAnswers[questionCode];
            }

            if(this.userService.authSet()) {
              // if no value was received in local storage, check the CAD
              if(questionCodeFormControl.value === undefined || questionCodeFormControl.value === null || questionCode === 'dob') {
                const questionResponse = await this.customerActivityService.getQuestionForID(questionCode).pipe(take(1)).toPromise();

                const dataItems = questionResponse[0]; // gets the queried questionCode (empty object if questionCode is not in CAD)

                // if the question code is in the CAD (not an empty object)
                if(Object.keys(dataItems).length !== 0) {
                  questionCodeFormControl.value = dataItems[questionCode];
                }
              }
            }
          }
        }
    }

  }

  private getScreeningAnswers(){
    const answers: any = {};

    for(const section of Object.values(this.screeningForm.value)){
      for(const group of Object.values(section)){
        for(const question of Object.keys(group)){
          answers[question] = group[question]
        }
      }
    }

    return answers;

  }


  public getQuestionComponent(code: string){

    for(const component of this.questions){
      
      if(component.question.key === code){
        return component.question;
      }

    }

  }

  public setQuestionOptions(code: string, value: any[]){

    for(const component of this.questions){
      
      if(component.question.key === code){
        component.question.options = [...value];
      }

    }

  }

  public setAnswer(questionCode: string, value: any){
 
    for(const sectionKey of Object.keys(this.screeningForm.value)){
      const section = this.screeningForm.value[sectionKey];
      for(const groupKey of Object.keys(section)){
        const group = section[groupKey];
        for(const question of Object.keys(group)){
          if(question === questionCode){

            this.screeningForm.get([sectionKey, groupKey, question]).setValue(value);
        
            this.screeningForm.get([sectionKey, groupKey, question]).updateValueAndValidity();
            break;
            // this.screeningForm.value[sectionKey][groupKey][question] = value;
          }
      }
    }
  }

 this.evaluateConditions('');


}

   // this.screeningForm.setValue(this.screeningForm.value)


}
