<div *ngIf="!data" class="loading-page">
  <img src="https://angular.ncoa.org/assets/images/loader.gif" alt="Loading" />
</div>

<age-well-planner *ngIf="data">
  <div class="vertical-page">
    <app-cm-banner >
    </app-cm-banner>
    <ncoa-vertical-landing-header
      *ngIf="data.ncoaVerticalLandingHeader && !data.assessmentIsCompleted"
      [title]="data.ncoaVerticalLandingHeader.title"
      [desc]="data.ncoaVerticalLandingHeader.desc"
      [image]="data.ncoaVerticalLandingHeader.image"
      [planSteps]="data.ncoaVerticalLandingHeader.planSteps"
      [assessmentCTA]="data.ncoaVerticalLandingHeader.assessmentCTA"
      [menu]="data.ncoaVerticalLandingHeader.menu"
    ></ncoa-vertical-landing-header>
    <div id = "main-content">
      <div *ngIf="data.featureContentBlock?.blocks.length > 0">
        <ncoa-feature-content-block  *ngFor="let component of data.featureContentBlock?.blocks"
        [image]="component.image" [description]="component.description"
        [title]="component.title" [orientation]="component.orientation"
        [background]="component.backgroundColor" [isButtonPresent]="component.isButtonPresent"
        [buttonCTAText]="component.buttonCTAText" [buttonCTALink]="component.buttonCTALink"
        ></ncoa-feature-content-block>
      </div>
      <ncoa-vertical-recommended
        *ngIf="data.recommended3ColGrid"
        [location]="data.recommended3ColGrid.location"
        [heading]="data.recommended3ColGrid.heading"
        [intro]="data.recommended3ColGrid.intro"
        [cards]="data.recommended3ColGrid.cards"
        [assessment]="data.recommended3ColGrid.assessment"
      ></ncoa-vertical-recommended>
      <ncoa-vertical-assessment
        *ngIf="data.assessment"
        #assessment
        [assessmentCode]="data.assessment.assessmentCode"
        [pageData]="data"
      ></ncoa-vertical-assessment>
      <ncoa-text-with-image
        *ngIf="data.emptyStatePromo"
        [alignment]="data.emptyStatePromo.alignment"
        [heading]="data.emptyStatePromo.heading"
        [intro]="data.emptyStatePromo.intro"
        [image]="data.emptyStatePromo.image"
        [cta]="data.emptyStatePromo.cta"
      ></ncoa-text-with-image>
      <div class = 'article-group-parent-container'>
        <div class = 'pattern-bg-container'>
          <div class = 'firstBgPattern'></div>
          <div class = 'secondBgPattern'></div>
        </div>
      <ncoa-article-grouping-grid
        *ngFor="let grouping of data.emptyArticleCardGrouping"
        [heading]="grouping.heading"
        [cards]="grouping.cards"
      ></ncoa-article-grouping-grid>
      </div>
      <ncoa-one-up
        *ngIf="data.emptyToolCard1Up"
        [type]="data.emptyToolCard1Up.type"
        [heading]="data.emptyToolCard1Up.heading"
        [card]="data.emptyToolCard1Up.card"
      ></ncoa-one-up>
      <ncoa-topic-listing
        *ngIf="data.ncoaTopicListing"
        [heading]="data.ncoaTopicListing.heading"
        [topics]="data.ncoaTopicListing.topics"
      ></ncoa-topic-listing>

    </div>
  </div>
</age-well-planner>
