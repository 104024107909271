<age-well-planner-assessment *ngIf="isLoaded && pageData && data">
  <div class="age-well-planner-assessment">
    <tools-assessment
      [isLeadGen]="true"
      [assessmentID]="assessmentID"
      [version]="version"
      [fullData]="data"
      [data]="data"
      [backCTA]="backCTA"
      (toggleLoadingOverlay)="toggleLoadingOverlay($event)"
      (onFinish)="onFinish($event)"
    ></tools-assessment>

    <div class="loading-overlay" *ngIf="showLoadingOverlay">
      <img src="https://angular.ncoa.org/assets/images/loader.gif" alt="Loading" />
    </div>
  </div>
</age-well-planner-assessment>

<div *ngIf="!isLoaded || !pageData || !data" class="loading-page">
  <img src="https://angular.ncoa.org/assets/images/loader.gif" alt="Loading" />
</div>
