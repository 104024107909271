<div *ngIf="!benefitsListing" class="loading-page">
  <img src="https://angular.ncoa.org/assets/images/loader.gif" alt="Loading" />
</div>

<age-well-planner *ngIf="benefitsListing">
  <div class="explore-all-benefits">
    <benefits-listing
      *ngIf="benefitsListing"
      [type]="benefitsListing.type"
      [backCta]="benefitsListing.backCTA"
      [header]="benefitsListing.header"
      [categoryOptions]="benefitsListing.categoryOptions"
    >
    </benefits-listing>
    <ncoa-vertical-pathing
      *ngIf="benefitsListing.verticalPathing"
      [heading]="benefitsListing.verticalPathing.heading"
      [items]="benefitsListing.verticalPathing.items"
    ></ncoa-vertical-pathing>
  </div>
</age-well-planner>
