import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { TaxonomyService } from '@services/taxonomy.service';
import { GlobalService } from '@services/global.service';
import { CustomSearchService } from './custom-search.service';
import { ToolsPrimaryConfigService } from './planner/tools-primary-config.service';
import { CustomerActivityService } from './planner/customer-activity.service';
import { KontentDeliveryService } from './kontent-delivery.service';
import { ComponentService } from './component.service';

import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { AudienceService } from './audience.service';
import { CategoryService } from './category.service';
import { SpecialPageService } from './special-page.service';
import { NcoaLandingPageService } from './ncoa-landing-page.service';

dayjs.extend(utc);

@Injectable({
  providedIn: 'root'
})
export class TaxonomyResolverService implements Resolve<any> {

  audienceTaxonomy: any = null;
  categoryTaxonomy: any = null;
  partnerTaxonomy: any = null;

  constructor(
    private kontentDeliveryService: KontentDeliveryService,
    private audienceService: AudienceService,
    private globalService: GlobalService,
    private categoryService: CategoryService,
    private customSearchService: CustomSearchService,
    private cas: CustomerActivityService,
    private toolsConfig: ToolsPrimaryConfigService,
    private specialPageService: SpecialPageService,
    private ncoaLandingPageService: NcoaLandingPageService
  ) {
    this.globalService.loadGlobalComponentsData();
  }

  async resolve(activeRoute: ActivatedRouteSnapshot) {
    const paths = activeRoute.url.map(({ path }) => path);
    let awpAssessmentShortcut = false;
    if (paths[0] === 'tools') {
      awpAssessmentShortcut = true;
    }

    const taxonomies: any = await this.kontentDeliveryService.getTaxonomies(null, { 'system.codename[in]': 'audiences,categories,partners' });
    this.audienceTaxonomy = taxonomies.taxonomies.find((taxonomy) => taxonomy.system.codename === 'audiences');
    this.categoryTaxonomy = taxonomies.taxonomies.find((taxonomy) => taxonomy.system.codename === 'categories');
    this.partnerTaxonomy = taxonomies.taxonomies.find((taxonomy) => taxonomy.system.codename === 'partners');

    if (awpAssessmentShortcut) {
      // this is inside awp
      const AWPConfig = await this.kontentDeliveryService.getItem(null, {
        'elements': 'url_slug',
        'system.type': 'awa_benefits_tool___configuration',
        'elements.tool_type[contains]': 'for_awa',
        'elements.url_slug':'age-well-planner',
        'limit': 1,
      });

      if (AWPConfig.items.length > 0) {
        const casData = await this.cas.getActivity().take(1).toPromise();
        const data = await this.toolsConfig.getConfig(casData)

        return {
          coreType: 'age-well-planner',
          data: await this.toolsConfig.getConfig(casData),
        };
      }
    }
    else if (paths.length === 1) {
      // audience page or other special pages (including AWP)
      // audience page
      const codename = paths[0].replace(/-/g, '_');
      const isAudiencePage = this.audienceTaxonomy.terms.find((term) => term.codename === codename);
      const landingPage = !isAudiencePage ? await this.kontentDeliveryService.getItem(null, {
        'system.type': 'ncoa___landing_page',
        'elements.url_slug': paths[0],
        'depth': 5,
        'limit': 1,
      }) : undefined;

      if (isAudiencePage) {
        return {
          coreType: 'taxonomy-page',
          components: await this.audienceService.getAudiencePageData(codename, this.categoryTaxonomy, this.audienceTaxonomy),
        };
        // end audience page
      } else if (landingPage?.items?.length) {
        return {
          coreType: 'ncoa-landing-page',
          components: await this.ncoaLandingPageService.getLandingPageData(landingPage)
        };
      } else {
        // this is inside awp
        const AWPConfig = await this.kontentDeliveryService.getItem(null, {
          'elements': 'url_slug',
          'system.type': 'awa_benefits_tool___configuration',
          'elements.tool_type[contains]': 'for_awa',
          'elements.url_slug': paths[0],
          'limit': 1,
        });

        if (AWPConfig.items.length > 0 || paths[0] === 'verify-user' || paths[0] === 'forgot-password' || paths[0] === 'sign-up' || paths[0] === 'sign-in') {
          const casData = await this.cas.getActivity().take(1).toPromise();

          return {
            coreType: 'age-well-planner',
            data: await this.toolsConfig.getConfig(casData),
          };
        }

        // this is a special page
        const pageLibrary = [];
        const specialPages = await this.kontentDeliveryService.getItem(null, {
          'system.type[in]': 'template___custom_search,template___other_taxonomy',
          'depth': 5,
        });
        const pageLibraryModularContents = specialPages.modular_content;

        specialPages.items.forEach((page) => {
          switch (page.system.type) {
            // custom search
            case 'template___custom_search': {
              pageLibrary.push({
                ...page,
                coreType: 'category-search-page',
                url: `/${page.elements.page_slug.value}`,
              });
              break;
            }

            // special page
            case 'template___other_taxonomy': {
              const name = page.system.name;
              const slug = name
                .trim()
                .toLowerCase()
                .replace(/\s+/g, '-')
                .replace(/[^\w\-]+/g, '')
                .replace(/\-\-+/g, '-')
                .replace(/^-+/, '')
                .replace(/-+$/, '');

              pageLibrary.push({
                ...page,
                coreType: 'special-page',
                url: `/${slug}`,
              });
            }
          }
        });

        const pageData = pageLibrary.find((page) => page.url === `/${paths.join('/')}`);

        if (pageData) {
          this.globalService.saveCurrentURL(`/${activeRoute.url.join('/')}`);

          switch (pageData.coreType) {
            // custom search
            case 'category-search-page': {
              return {
                coreType: pageData.coreType,
                customSearchData: this.customSearchService.getCustomSearchPageData(pageData, pageLibraryModularContents),
              };
            }

            // special page
            case 'special-page': {
              return {
                coreType: pageData.coreType,
                components: await this.specialPageService.getSpecialPageData(pageData, pageLibraryModularContents),
              };
            }
          }
        }
      }
    } else {

      // category pages or pages under AWP
      // category page
      const audienceCodename = paths[0].replace(/-/g, '_');
      const found = this.audienceTaxonomy.terms.find((term) => term.codename === audienceCodename);

      // check for partner codename
      const isPartner = this.partnerTaxonomy.terms.find((term) => term.codename === audienceCodename);

      if (found) {
        const slug = paths.join('/');
        const components = await this.categoryService.getCategoryPageData(slug, this.categoryTaxonomy, this.audienceTaxonomy);

        return {
          coreType: 'taxonomy-page',
          components,
        };
      } else if (isPartner) {
        const pageSlug = paths[1];

        const partnerPage = await this.kontentDeliveryService.getItem(null, {
          'system.type': 'partner_landing_page',
          'elements.url_slug': pageSlug,
          'elements.partners[contains]': audienceCodename,
          'limit': 1,
        });

        return {
          coreType: 'partner',
          data: partnerPage
        }
      }
      // page under awp
      else {
        // this is inside awp
        const AWPConfig = await this.kontentDeliveryService.getItem(null, {
          'elements': 'url_slug',
          'system.type': 'awa_benefits_tool___configuration',
          'elements.tool_type[contains]': 'for_awa',
          'elements.url_slug': paths[0],
          'limit': 1,
        });

        if (AWPConfig.items.length > 0) {
          const casData = await this.cas.getActivity().take(1).toPromise();

          return {
            coreType: 'age-well-planner',
            data: await this.toolsConfig.getConfig(casData),
          };
        }
      }
    }
  }
}
