import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import fetch from 'node-fetch';
import { TransferState, makeStateKey, StateKey } from '@angular/platform-browser';

import { ErrorService } from '@services/error.service';
import { environment } from '@environment';

import { TestKontentArticle } from '@ncoa-types';
import {
  PROJECT_ID,
  PRIMARY_KEY,
  API_BASE_URL
} from '@config/kontent-api';

import { DeliveryClient } from '@kentico/kontent-delivery';
import { RichTextService } from './rich-text-resolver.service';

const RETRIES = environment.prerender === 'all' ? 2 : 0;

// console.log({ environment: environment.environment });

@Injectable({
  providedIn: 'root'
})
export class KontentDeliveryService {
  private deliveryClient: any;
  private isBrowser: boolean;
  data: any;

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private errorService: ErrorService,
    private transferState: TransferState,
    private richTextResolver: RichTextService,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);


    this.deliveryClient = new DeliveryClient({
      projectId: PROJECT_ID,
      previewApiKey: PRIMARY_KEY,
      globalQueryConfig:  {
          usePreviewMode: environment.environment === 'preview', // Queries the Delivery Preview API.
      }
    });

    
  }

  public get client(): DeliveryClient{
    return this.deliveryClient;
  }
  

  async getItems(type: string, depth = 3, retries = 0){

    const request = this.deliveryClient
    .items()
    .type(type)
    .depthParameter(depth)
   .languageParameter('default')
    .toPromise();

    return request.then(
      async (rawAPIData: any) => {
        if (
          !rawAPIData.items?.length
        ){

          throw new Error('Page not Found');
        }
        return rawAPIData.items;
      }
    )
    .catch(err => {
      if ( retries > 0 ) {
        return new Promise((resolve) => setTimeout(() => {
          resolve(this.getItems(type, depth, retries - 1));
        }, 1000));
      }
      else {
        throw err;
      }
    });
  }

  getItemsIn(codeNames, paramsObject = {}, throwIfNotFound = false, retries = RETRIES) {
    const isPreview = environment.environment === 'preview';
    let endpointURL = `${API_BASE_URL}/${PROJECT_ID}/items/`;

    paramsObject['system.codename[in]'] = codeNames;

    endpointURL += '?' + Object.entries(paramsObject).map(([ key, value ]) => `${key}=${value}`).join('&');

    const request = isPreview ? this.fetchPreviewURL(endpointURL, codeNames) : this.getAPIState(endpointURL);

    return request.then((rawAPIData: any) => {
      if ( !rawAPIData ) {
        // console.log('endpointURL',endpointURL);
      }

      if (
        throwIfNotFound &&
        !rawAPIData?.item &&
        !rawAPIData.items?.length
      ){
        this.errorService.notFound();
        throw new Error('Page not Found');
      }
      return rawAPIData;
    })
    .catch(err => {
      if ( retries > 0 ) {
        return new Promise((resolve) => setTimeout(() => {
          resolve(this.getItem(codeNames, paramsObject, throwIfNotFound, retries - 1));
        }, 1000));
      }
      else {
        throw err;
      }
    });
  }

  getItem(codeName, paramsObject = {}, throwIfNotFound = false, retries = RETRIES) {
    const isPreview = environment.environment === 'preview';
    let endpointURL = `${API_BASE_URL}/${PROJECT_ID}/items/${ !isPreview ? codeName || '' : ''}`;

    if ( isPreview && codeName ) {
      paramsObject['system.codename'] = codeName;
    }
    endpointURL += '?' + Object.entries(paramsObject).map(([ key, value ]) => `${key}=${value}`).join('&');

    const request = isPreview ? this.fetchPreviewURL(endpointURL, codeName) : this.getAPIState(endpointURL);

    return request.then((rawAPIData: any) => {
      if ( !rawAPIData ) {
        // console.log('endpointURL',endpointURL);
      }

      if (
        throwIfNotFound &&
        !rawAPIData?.item &&
        !rawAPIData.items?.length
      ){
        this.errorService.notFound();
        throw new Error('Page not Found');
      }
      return rawAPIData;
    })
    .catch(err => {
      if ( retries > 0 ) {
        return new Promise((resolve) => setTimeout(() => {
          resolve(this.getItem(codeName, paramsObject, throwIfNotFound, retries - 1));
        }, 1000));
      }
      else {
        throw err;
      }
    });
  }

  async getItemAndCache(codename, params = {}) {
    let url = null;

    if (codename) {
      url = `${API_BASE_URL}/${PROJECT_ID}/items/${codename}?${new URLSearchParams(params)}`;
    } else {
      url = `${API_BASE_URL}/${PROJECT_ID}/items?${new URLSearchParams(params)}`;
    }

    const doNetworkRequest = () => {
      return fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${PRIMARY_KEY}`,
        },
      })
      .then(resp => resp.json())
      .then(data => {
        if (this.isBrowser && caches) {
          const expiration = new Date();
          expiration.setMinutes(expiration.getMinutes() + 10) // 10 minutes expiration time

          const json = new Response(JSON.stringify({
            expiration,
            data,
          }), {
            headers: {
              'content-type': 'application/json',
            },
          });

      
            caches.open('json-cache')
            .then(cache => cache.put(url, json))
            .catch((e) => console.error("cache put error", e));

        }

        return data;
      });
    };

    if (this.isBrowser && caches) {
      try{
        return await caches.match(url)
        .then(async (resp) => {
          if (resp) {
            let { expiration, data } = await resp.json();

            expiration = new Date(expiration);
            const now = new Date();

            if (expiration < now) {
              return doNetworkRequest();
            } else {
              return data;
            }
          } else {
            return doNetworkRequest();
          }
        });

      }catch(err){
        if (err instanceof DOMException){
          return await doNetworkRequest();
        }

        throw err
      }

    } else {
      return await doNetworkRequest();
    }
  }

  getFeed(codeName, paramsObject = null) {
    const isPreview = environment.environment === 'preview';
    let endpointURL = `${API_BASE_URL}/${PROJECT_ID}/items-feed/${ !isPreview ? codeName || '' : ''}`;

    if ( paramsObject ) {
      if ( isPreview && codeName ) {
        paramsObject['system.codename'] = codeName;
      }
      endpointURL += '?' + Object.entries(paramsObject).map(([ key, value ]) => `${key}=${value}`).join('&');
    }

    const request = isPreview ? this.fetchPreviewURL(endpointURL, codeName, true) : this.getAPIState(endpointURL);

    return request;
  }

  async getFeedWithHeader(codeName, paramsObject = null, customHeader = null) {
    let endpointURL = `${API_BASE_URL}/${PROJECT_ID}/items-feed/${codeName || ''}`;

    if ( paramsObject ) {
      endpointURL += '?' + Object.entries(paramsObject).map(([ key, value ]) => `${key}=${value}`).join('&');
    }

    let headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${PRIMARY_KEY}`
    };

    if (customHeader) {
      headers = {...headers, ...customHeader};
    }

    let token = null;

    const feedStream = await fetch(endpointURL, { headers });
    token = feedStream.headers.get('X-Continuation') || '';
    const data = await feedStream.json();

    return { token, data }
  }

  async getSearchFeed(customEndpoint = '') {
    const params = {
      'system.type[in]': 'ncoa_article_content,taxonomy_custom_content,author,standard_page,standard_page__special',
      'limit': 1800,
      'skip': 0,
      'includeTotalCount': true,
      'elements': 'audiences,categories,event_date_time,url,display_date,primary_image,title,header_image,category_page_url,author_name,author_headshot,author_url_slug,parent_page',
      'depth': 1
    };

    let endpointURL = `${API_BASE_URL}/${PROJECT_ID}/items/`;
    endpointURL += '?' + Object.entries(params).map(([ key, value ]) => `${key}=${value}`).join('&');

    if (customEndpoint != '') {
      endpointURL = customEndpoint;
    }

    let headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${PRIMARY_KEY}`
    };

    let token = null;

    const feedStream = await fetch(endpointURL, { headers });
    const data = await feedStream.json();

    return { token, data }
  }

  async getFullFeed(params = null) {
    const fullResults = [];
    const modular_content = {};
    const pagination = {};
    let itemsCount = 0;

    const initialRequest = await this.getSearchFeed();
    fullResults.push(...initialRequest.data.items);
    itemsCount += initialRequest.data.pagination.count;
    Object.assign(modular_content, initialRequest.data.modular_content);
    Object.assign(pagination, initialRequest.data.pagination);

    const itemsCountLimit = initialRequest.data.pagination.total_count;

    while (itemsCount < itemsCountLimit) {
      const additionalRequest = await this.getSearchFeed(initialRequest.data.pagination.next_page);
      fullResults.push(...additionalRequest.data.items);
      itemsCount += additionalRequest.data.pagination.count;
      Object.assign(modular_content, initialRequest.data.modular_content);
      Object.assign(pagination, initialRequest.data.pagination);
    }

    return {
      items: fullResults,
      modular_content,
      pagination
    };
  }

  getTaxonomies(taxonomyGroup, paramsObject = null) {
    let endpointURL = `${API_BASE_URL}/${PROJECT_ID}/taxonomies/${taxonomyGroup || ''}`;

    if ( paramsObject ) {
      endpointURL += '?' + Object.entries(paramsObject).map(([ key, value ]) => `${key}=${value}`).join('&');
    }

    return this.getAPIState(endpointURL);
  }

  async getTaxonomiesAndCache(taxonomyGroup, paramsObject = null) {
    let endpointURL = `${API_BASE_URL}/${PROJECT_ID}/taxonomies/${taxonomyGroup || ''}`;

    if ( paramsObject ) {
      endpointURL += '?' + Object.entries(paramsObject).map(([ key, value ]) => `${key}=${value}`).join('&');
    }

    const doNetworkRequest = () => {
      return fetch(endpointURL, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${PRIMARY_KEY}`,
        },
      })
      .then(resp => resp.json())
      .then(data => {
        if (this.isBrowser && caches) {
          const expiration = new Date();
          expiration.setMinutes(expiration.getMinutes() + 10) // 10 minutes expiration time

          const json = new Response(JSON.stringify({
            expiration,
            data,
          }), {
            headers: {
              'content-type': 'application/json',
            },
          });

          caches.open('json-cache')
            .then(cache => cache.put(endpointURL, json))
            .catch(e => console.log("cache error", e));
        }

        return data;
      });
    }

    if (this.isBrowser && caches) {
      try{

        return await caches.match(endpointURL)
        .then(async (resp) => {
          if (resp) {
            let { expiration, data } = await resp.json();

            expiration = new Date(expiration);
            const now = new Date();

            if (expiration < now) {
              return doNetworkRequest();
            } else {
              return data;
            }
          } else {
            return doNetworkRequest();
          }
        });

      }catch(err){
        if(err instanceof DOMException){ // caching isnt allowed just do the request
          return doNetworkRequest();
        }
        throw err
      }

    } else {
      return await doNetworkRequest();
    }
  }

  /**
   * Extract a specified modular content from a Kontent API Item
   * @param {object} rawItemData - The raw Kontent API Item Data
   * @param {string|array} elementName - The module content's name
   */
  extractModularContent(rawItemData, elementName) {
    if ( typeof elementName === 'string' ) {
      return rawItemData.modular_content[elementName] || null;
    }

    if ( Array.isArray(elementName) ) {
      return elementName.map(name => {
        return rawItemData.modular_content[name] || null;
      });
    }

    return null;
  }

  /**
   * Extract given elements from a Kentiko Item  in a flat objet formatt
   * @param {array} elementNames - List of element names to extract
   */
  extractItemElements(rawItemData, elementNames: string[] = null) {
    if ( !rawItemData?.elements ) {
      return {};
    };

    const elementsToExtract = elementNames ? elementNames : ['system', ...Object.keys(rawItemData.elements)];

    return elementsToExtract.reduce((selectedElements, elementName) => {
      if ( elementName in rawItemData.elements ) {
        const element = rawItemData.elements[elementName];
        selectedElements[elementName] = element.value;
        if(element.type === 'rich_text'){
          selectedElements[elementName] = this.richTextResolver.resolveRichText(element);
        }
      }else if (elementName === 'system'){
        selectedElements['system'] = rawItemData.system
      }
      return selectedElements;
    }, {});
  }

  /**
   * Extract given system properties from a Kentiko Item  in a flat objet format
   * @param {array} elementNames - List of element names to extract
   */
  extractSystemElements(rawItemData, systemNames: string[] = null) {
    if ( !rawItemData?.system ) {
      return {};
    };

    const systemItemsToExtract = systemNames ? systemNames : Object.keys(rawItemData.system);

    return systemItemsToExtract.reduce((selectedSystemItems, systemName) => {
      if ( systemName in rawItemData.system ) {
        selectedSystemItems[systemName] = rawItemData.system[systemName];
      }
      return selectedSystemItems;
    }, {});
  }

  fetch(url) {
    return fetch(url, {
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + PRIMARY_KEY
      }
    })
    .then(response => {
      if ( response.status >= 400 ) {
        throw {
          status: response.status,
          statusText: response.statusText,
        };
      }

      return response.json();
    })
    .catch(this.onAPIError.bind(this))
  }

  async getAPIState(fullURL) {
    const stateKey: any = makeStateKey(fullURL);
    const hasKey = this.transferState.hasKey(stateKey);

    return new Promise(async (resolve) => {
      if ( hasKey ) {
        const result = this.transferState.get(stateKey, null);
        resolve(result);
      }
      else {
        const data = await this.fetch(fullURL);
        this.transferState.set(stateKey, data);
        resolve(data);
      }
    });
  }

  onAPIError(error, url) {
    if ( error.status >= 500 ) {
      this.errorService.serverError();
    }
    console.trace(url, error);
  }

  fetchPreviewURL(url, codeName, isItemsFeed  = false) {
    const selectedMethod = isItemsFeed ? 'itemsFeedAll' : 'items';

    return new Promise(resolve => {
      this.deliveryClient[selectedMethod]()
        .withUrl(url)
        .toObservable()
        .subscribe(
          response => resolve(this.formatPreviewResponse(response, codeName)),
          error => this.onAPIError(error, url)
        );
    });
  }

  formatPreviewResponse(rawPreviewResponse, codeName) {
    const rawAPIData: any = {
      modular_content: {}
    };

    if ( 'item' in rawPreviewResponse ) {
      rawAPIData.item = rawPreviewResponse.item._raw;

    }

    if ( 'items' in rawPreviewResponse ) {
      if ( codeName && rawPreviewResponse.items.length ) {
        rawAPIData.item = rawPreviewResponse.items[0]._raw;
      } else {
        rawAPIData.items = rawPreviewResponse.items.map(({ _raw }: any) => _raw);
      }
    }

    if ( 'linkedItems' in rawPreviewResponse ) {
      Object.entries(rawPreviewResponse.linkedItems).forEach(([ key, value ]: any[]) => {
        rawAPIData.modular_content[key] = value._raw;
      });
    }

    return rawAPIData;
  }

}
