<div class="parent-container">

    <div class="checkbox">
      <div class="box">
        <input type="checkbox" [name]="name" [id]="id" [checked]="checked" (click)="changeValue($event)" [attr.aria-checked]="checked" [disabled]="disabled">
        <img *ngIf="checked" src="http://angular.ncoa.org/assets/images/icon-check-dark.svg">
      </div>
      <label>
        <ng-content></ng-content>
      </label>

      <!-- <span>
        <ng-content></ng-content>
      </span> -->
    </div>
    <!-- <label class="checkbox" [for]="id">
      <div class="box">
        <input type="checkbox" [name]="name" [id]="id" [checked]="checked" (click)="changeValue($event)" [attr.aria-checked]="checked" [disabled]="disabled">
        <svg-icon *ngIf="checked" name="check"></svg-icon>
      </div>
      <span>
        <ng-content></ng-content>
      </span>
    </label> -->
  </div>
