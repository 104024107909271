import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { GlobalService } from '@services/global.service';

@Component({
  selector: 'ncoa-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {

  privacy: {
    text: string;
    url: string;
  } = {
    text: 'Privacy Policy',
    url: '#',
  };
  dismissed = false;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    if(this.getCookie('dismiss-cookies')){
      this.dismissed = true;
    }
  }

  dismiss() {
    document.getElementById('privacy-notice').style.visibility = 'hidden';
    this.setSessionCookie('dismiss-cookies', 'true');
    this.dismissed = true;
    // id = privacy-notice add style visisbility: hidden

  }

  setSessionCookie(name: string, value: string) {
    document.cookie = `${name}=${value};path=/`;
  }

  getCookie(name: string): string | null {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
}
