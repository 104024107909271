<article [ngClass]="{
  'article-card': true,
  'article-card--featured': type === 'featured',
  'article-card--minimal': type === 'minimal'
}">
  <div class="article-card__content">
    <div *ngIf="type !== 'minimal'">
      <div class="article-card__image" *ngIf="image.url; else elseBlock">
        <img *ngIf="!image.responsive" src="{{ image.url }}" alt="{{ image.alt }}" title="{{ image.alt }}"width="100%" height="100%" loading="lazy" />
        <picture *ngIf="image.responsive">
          <source *ngFor="let item of image.responsive" [srcset]="item.url" [media]="item.media" />
          <img [src]="image.responsive[0].url" [alt]="image.alt" width="100%" height="100%" loading="lazy"/>
        </picture>
      </div>
      <ng-template #elseBlock class="article-card__image">
        <div class="article-card__image default">
          <picture *ngIf="articleType === 'category' || articleType === 'page'">
            <source srcset="http://angular.ncoa.org/assets/images/content-package-midnight-mobile.png" media="all and (max-width: 767px)" />
            <source srcset="http://angular.ncoa.org/assets/images/content-package-midnight-tablet.png" media="(min-width: 768px) and (max-width: 1023px)" />
            <source srcset="http://angular.ncoa.org/assets/images/content-package-midnight-desktop.png" media="all and (min-width: 768px)" />
            <img src="http://angular.ncoa.org/assets/images/content-package-midnight-desktop.png" [alt]="title" loading="lazy"/>
          </picture>

          <picture *ngIf="articleType === 'article'">
            <source srcset="http://angular.ncoa.org/assets/images/content-package-coral-mobile.png" media="all and (max-width: 767px)" />
            <source srcset="http://angular.ncoa.org/assets/images/content-package-coral-tablet.png" media="(min-width: 768px) and (max-width: 1023px)" />
            <source srcset="http://angular.ncoa.org/assets/images/content-package-coral-desktop.png" media="all and (min-width: 768px)" />
            <img src="http://angular.ncoa.org/assets/images/content-package-coral-desktop.png" [alt]="title" width="100%" height="100%" loading="lazy" />
          </picture>

          <picture *ngIf="articleType === 'event'">
            <source srcset="http://angular.ncoa.org/assets/images/content-package-green-mobile.png" media="all and (max-width: 767px)" />
            <source srcset="http://angular.ncoa.org/assets/images/content-package-green-tablet.png" media="(min-width: 768px) and (max-width: 1023px)" />
            <source srcset="http://angular.ncoa.org/assets/images/content-package-green-desktop.png" media="all and (min-width: 768px)" />
            <img src="http://angular.ncoa.org/assets/images/content-package-green-desktop.png" [alt]="title" width="100%" height="100%" loading="lazy"/>
          </picture>
        </div>
      </ng-template>
    </div>
    <div class="article-card__body">
      <div class="green-circle"></div>
      <div class="article-card__eyebrow" *ngIf="eyebrow">{{ eyebrow }}</div>
      <a class="article-card__title" [href]="cta.url">{{ title }}</a>
      <a href="{{ cta.url }}" class="article-card__cta" [attr.aria-label]="'Read article about ' + title" role="button">
        {{ cta && cta.text ? cta.text : 'Read Article' }}
      </a>
    </div>
  </div>
</article>
