<div *ngIf="!data" class="loading-page">
  <img src="https://angular.ncoa.org/assets/images/loader.gif" alt="Loading" />
</div>

<age-well-planner *ngIf="data">
  <div class="subtopic subtopic--expert">
    <ncoa-subtopic-header
      *ngIf="data.subtopicHeader"
      [heading]="data.subtopicHeader.heading"
      [photo]="data.subtopicHeader.photo"
      [menu]="data.subtopicHeader.menu"
      [backCTA]="data.subtopicHeader.backCTA"
    ></ncoa-subtopic-header>
    <div id="main-content">
      <ncoa-text-with-image
        *ngIf="data.promoTop"
        [alignment]="data.promoTop.alignment"
        [heading]="data.promoTop.heading"
        [intro]="data.promoTop.intro"
        [image]="data.promoTop.image"
        [cta]="data.promoTop.cta"
      ></ncoa-text-with-image>

      <ncoa-one-up
        *ngIf="data.trustedExperts && data.trustedExperts.cards.length === 1"
        [type]="data.trustedExperts.type"
        [heading]="data.trustedExperts.heading"
        [card]="data.trustedExperts.cards[0]"
      ></ncoa-one-up>

      <ncoa-single-col-card
        *ngIf="data.trustedExperts && data.trustedExperts.cards.length > 1"
        [heading]="data.expertListingHeadline"
        [cards]="data.trustedExperts.cards"
      ></ncoa-single-col-card>

      <ncoa-one-up
        *ngIf="data.toolCard1up"
        [type]="data.toolCard1up.type"
        [heading]="data.toolCard1up.heading"
        [card]="data.toolCard1up.card"
        [cta]="data.toolCard1up.cta"
      ></ncoa-one-up>

      <ncoa-topic-listing
        *ngIf="data.singleColCardList"
        [heading]="data.singleColCardList.heading"
        [topics]="data.singleColCardList.topics"
      ></ncoa-topic-listing>
    </div>
  </div>
</age-well-planner>
