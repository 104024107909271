<ng-container *ngIf="pageData">
  <div class="age-well-planner-forgot-password">
    <div class="forgot-password">
      <div class="forgot-password__wrapper">
        <a class="back-cta" (click) = 'goBack($event)' [title]="pageData.backCTA.text">
          <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M6.86842 0.547222L0.315788 7.25062C0.105263 7.46513 0 7.73326 0 8.0014C0 8.26954 0.105263 8.53767 0.315788 8.75218L6.86842 15.4556C7.28947 15.8846 7.94737 15.8846 8.36842 15.4556C8.78947 15.0266 8.78947 14.3562 8.36842 13.9272L3.57895 9.07395L18.9474 9.07395C19.5263 9.07395 20 8.5913 20 8.0014C20 7.4115 19.5263 6.92886 18.9474 6.92886L3.57895 6.92886L8.34211 2.04878C8.76316 1.61977 8.76316 0.949426 8.34211 0.52014C7.94737 0.117936 7.28947 0.117936 6.86842 0.547222Z"
              fill="#0B4A5D" />
          </svg>
          <div><span>{{ pageData.backCTA.text }}</span></div>
        </a>
        <div class="main" *ngIf="stageCount === 1"  id = 'main-content'>
          <div class="eyebrow">{{ pageData.eyebrow }}</div>
          <h1 class="title">{{ pageData.title }}</h1>
          <p class="desc">{{ pageData.desc }}</p>
          <form action="" (ngSubmit)="onSubmit($event)" class="form-container">
            <div class="field">
              <div class="form-control">
                <label for="email">Email Address</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  [(ngModel)]="email.value"
                  [ngClass]="email.status.code"
                />
                <div class="{{ email.status.code }}" *ngIf="email.status.code === 'error' || email.status.code === 'idle'">
                  {{ email.status.message }}
                </div>
              </div>
              <div class="link-sent" *ngIf="linkSent" role='alert'>
                <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0996 21.166C12.4512 21.5176 13.0488 21.5176 13.4004 21.166L23.7363 10.8301C24.0879 10.4785 24.0879 9.88086 23.7363 9.5293L22.4707 8.26367C22.1191 7.91211 21.5566 7.91211 21.2051 8.26367L12.7676 16.7012L8.79492 12.7637C8.44336 12.4121 7.88086 12.4121 7.5293 12.7637L6.26367 14.0293C5.91211 14.3809 5.91211 14.9785 6.26367 15.3301L12.0996 21.166Z" fill="#0B4A5D"/>
                  <circle cx="15" cy="15" r="14" stroke="#0B4A5D" stroke-width="2"/>
                </svg>
                Link sent!
              </div>
            </div>
            <a [href]="pageData.contactCTA.url" [title]="pageData.contactCTA.text" class="contact-cta">
              {{ pageData.contactCTA.text }}
            </a>
            <div class="footer">
              <a [href]="pageData.signInCTA.url" [title]="pageData.signInCTA.text" class="signin-cta">
                {{ pageData.signInCTA.text }}
              </a>
              <button type="submit" class="submit-btn">{{ linkSent ? 'Resend Link' : 'Send Link' }} </button>
            </div>
          </form>
        </div>
        <div class="main" *ngIf="stageCount === 2"  id = 'main-content'>
          <div class="eyebrow">Forgot Password</div>
          <h1 class="title">Reset password.</h1>
          <p class="desc">
            Your password must have:<br />
            At least 8 characters<br />
            At least 1 uppercase and lowercase character<br />
            At least 1 number and 1 special character @$!%*?&<br />
          </p>
          <form action="" (ngSubmit)="onSubmit($event)" class="form-container form-stg-2">
            <div class="row">
              <div class="col">
                <label for="password">New Password</label>
                <input
                  [(ngModel)]="password.value"
                  type="password"
                  name="password"
                  id="password"
                  [ngClass]="password.status.code"
                />
                <div class="error" *ngIf="password.status.code === 'error'">
                  {{ password.status.message }}
                </div>
              </div>
              <div class="col">
                <label for="cpassword">Confirm New Password</label>
                <input
                  type="password"
                  name="cpassword"
                  id="cpassword"
                  [(ngModel)]="confirmPassword.value"
                  [ngClass]="confirmPassword.status.code"
                />
                <div class="error" *ngIf="confirmPassword.status.code === 'error'">
                  {{ confirmPassword.status.message }}
                </div>
              </div>
            </div>
            <button type="submit" class="submit-btn submit-btn--stg-2">Finish</button>
          </form>
        </div>
      </div>
    </div>
    <div *ngIf="showLoadingModal" class="age-well-planner-forgot-password__loading-modal">
      <img src="https://angular.ncoa.org/assets/images/loader.gif" alt="Loading" />
    </div>
  </div>
</ng-container>
