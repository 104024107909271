<ng-container *ngIf="loading, else loaded">
    <div style="display: flex;justify-content: center;">
        <img style="height: 128px; width: 128px;" src="https://angular.ncoa.org/assets/images/loader.gif" alt="Loading..." />
    </div>
</ng-container>

<ng-template #loaded>
    <ng-container *ngIf="(activeTodosTotal + completedTodosTotal + archivedTodosTotal) > 0 else screeningNotCompleted">
        <div *ngIf="!currentTodo; else todoPage" class="vertical-plan-content">
            <!-- to do list view -->
            <section *ngIf="!showScreeningAnswers" class="plan-to-do-lists" [ngClass]="
                {'active': currentTodoList === 'activeTodosRef', 
                'completed': currentTodoList === 'completedTodosRef', 
                'archived': currentTodoList === 'archivedTodosRef'
                }">



                <section #header class="plan-to-do-lists-headers" [ngClass]="{'sticky': headerSticky}">

                    <div (click)="currentTodoList = 'activeTodosRef'" class="lists-header selected"
                        [ngClass]="{'selected': currentTodoList === 'activeTodosRef'}">
                        <h1>Your ToDos</h1>

                        <div class="list-header-number">
                            <span>{{activeTodosTotal}}</span>
                        </div>

                    </div>

                    <div (click)="currentTodoList = 'completedTodosRef'" class="lists-header"
                        [ngClass]="{'selected': currentTodoList === 'completedTodosRef'}">
                        <h1>Completed</h1>

                        <div class="list-header-number">
                            <span>{{completedTodosTotal}}</span>
                        </div>

                    </div>

                    <div (click)="currentTodoList = 'archivedTodosRef'" class="lists-header"
                        [ngClass]="{'selected': currentTodoList === 'archivedTodosRef'}">
                        <h1>Archived</h1>

                        <div class="list-header-number">
                            <span>{{archivedTodosTotal}}</span>
                        </div>

                    </div>

                </section>

                <div class="list">
                    <ng-container *ngIf="!currentTodo">
                        <ng-container
                            *ngIf="currentTodoList === 'activeTodosRef'; then activeTodosTemplate"></ng-container>
                        <ng-container
                            *ngIf="currentTodoList === 'completedTodosRef'; then completedTodosTemplate"></ng-container>
                        <ng-container
                            *ngIf="currentTodoList === 'archivedTodosRef'; then archivedTodosTemplate"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="currentTodo; then todoPage"></ng-container>
                </div>
            </section>

            <section *ngIf="!showScreeningAnswers" class="plan-view-saved-answers">
                <button (click)="viewAnswers()">View Answers </button>
            </section>
            <!-- saved answers view-->
            <section *ngIf="showScreeningAnswers">
                <ng-container *ngTemplateOutlet="showSavedScreeningAnswers"></ng-container>
            </section>


        </div>

        <ng-template #todoPage>
            <app-client-todo-page [client]="client" (onBackButton)="onBackButton($event)" [content]="{}"
                [todo]="currentTodo"></app-client-todo-page>
        </ng-template>
    </ng-container>

</ng-template>

<ng-template #screeningNotCompleted>
    <span class="message">
        <p>There is no Budget CheckUp Plan to display here, because either: </p>
            <p>(A) this client has not completed their Budget CheckUp yet, or </p>
            <p>(B) this client completed their Budget CheckUp at some point in the past, then deleted that plan, and has not completed a new Budget CheckUp yet.</p>
        <p>Please coordinate with the client to complete their Budget CheckUp.</p>
    </span> 
</ng-template>

<ng-template #showSavedScreeningAnswers>
    <app-client-screening [questionsAnswers]="clientQuestionsAnswers"
        (onBackButton)="onBackButton($event)"></app-client-screening>
</ng-template>




<ng-template #activeTodosTemplate>
    <app-to-do-list [content]="activeTodos" [source]="planType" (onComplete)="onTodoAction($event, 'onComplete')"
        (onArchived)="onTodoAction($event, 'onArchived')" (onUpdateListTotal)="onUpdateListTotal($event)"
        [todos]="activeTodos" [scrollToTarget]="'back-btn'">
    </app-to-do-list>

</ng-template>

<ng-template #completedTodosTemplate>

    <app-to-do-list [content]="completedTodos" [source]="planType" (onIncomplete)="onTodoAction($event, 'onIncomplete')"
        (onArchived)="onTodoAction($event, 'onArchived')" (onUpdateListTotal)="onUpdateListTotal($event)"
        [todos]="completedTodos" [scrollToTarget]="'back-btn'">
    </app-to-do-list>

</ng-template>

<ng-template #archivedTodosTemplate>

    <app-to-do-list [content]="archivedTodos" [source]="planType" (onComplete)="onTodoAction($event, 'onComplete')"
        (onIncomplete)="onTodoAction($event, 'onIncomplete')" (onUnarchived)="onTodoAction($event, 'onUnarchived')"
        (onUpdateListTotal)="onUpdateListTotal($event)" [todos]="archivedTodos" [scrollToTarget]="'back-btn'">
    </app-to-do-list>

</ng-template>