<div *ngIf="!data" class="loading-page">
  <img src="https://angular.ncoa.org/assets/images/loader.gif" alt="Loading" />
</div>

<age-well-planner *ngIf="data">
  <div class="vertical-page-benefits-look-up">
    <ncoa-vertical-landing-header
      [title]="data.ncoaVerticalLandingHeader.title"
      [desc]="data.ncoaVerticalLandingHeader.desc"
      [image]="data.ncoaVerticalLandingHeader.image"
      [assessmentCTA]="data.ncoaVerticalLandingHeader.assessmentCTA"
      [menu]="data.ncoaVerticalLandingHeader.menu"
    ></ncoa-vertical-landing-header>
    <benefits-listing
      [type]="data.benefitsListing.type"
      [backCta]="data.benefitsListing.backCta"
      [header]="data.benefitsListing.header"
      [subHeaderCTA]="data.benefitsListing.subHeaderCTA"
      [selectedCategory]="data.benefitsListing.selectedCategory"
    ></benefits-listing>
  </div>
</age-well-planner>
