<section class="component related-articles">
  <div class="related-articles__container">
    <div class="related-articles__wrapper">
      <h2 class="headline">Related Articles</h2>
      <div class="entries-container">
        <ul class="entries">
          <li *ngFor="let entry of entries">
            <div class="section" *ngIf="entry.image.url; else elseBlock">
              <img *ngIf="entry.image && entry.image.url && !entry.image.responsive" [attr.src]="entry.image.url" [attr.alt]="entry.image.alt" [attr.title]="entry.image.alt" width="100%" height="100%" />
              <picture *ngIf="entry.image.responsive">
                <source *ngFor="let item of entry.image.responsive" [srcset]="item.url" [media]="item.media" />
                <img [src]="entry.image.responsive[0].url" [alt]="entry.image.alt" width="100%" height="100%" />
              </picture>
            </div>
            <ng-template #elseBlock class="section">
              <div class="section default">
                <img *ngIf="entry.event_date_time" src="http://angular.ncoa.org/assets/images/content-package-green-desktop.png" [alt]="entry.title" width="100%" height="100%" />
                <img *ngIf="!entry.event_date_time" src="http://angular.ncoa.org/assets/images/content-package-coral-desktop.png" [alt]="entry.title" width="100%" height="100%" />
              </div>
            </ng-template>
            <p class="date">{{ entry.publishDate }}</p>
            <h2 class="title"><a href="{{ entry.ctaUrl }}">{{ entry.title }}</a></h2>
            <a href="{{ entry.ctaUrl }}" [title]="entry.title" [attr.aria-label]="'Read article about ' + entry.title" class="cta-link" role="button">
              {{ entry.type === 'event' ? 'View Event Details' : 'Read Article' }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
