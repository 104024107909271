<section class="global-footer" role="contentinfo">
  <div class="global-footer__container">
    <div class="global-footer__wrapper">
      <div class="global-footer__top">
        <div class="global-footer__logo-donate">
          <img src="{{ logo?.url }}" alt="NCOA" class="logo" height="80px" width="105.37px" role="img" />
          <a href="{{buttonLink?.url}}" [title]="buttonLink?.text" class="donate-button">{{ buttonLink?.text }}</a>
        </div>
        <div class="global-footer__section">
          <div class="global-footer__links">
            <div class="global-footer__links--nav">
              <div class="section">
                <ul>
                  <li class="global-footer__link-item" *ngFor="let link of links?.slice(0,3);">
                    <a href="{{ link?.url }}" [attr.aria-label]="'click here to go to ' + link.text" class="global-footer__link">
                      {{ link.text }}
                    </a>
                  </li>
                </ul>
              </div>
              <div class="section" *ngIf="links?.length > 3">
                <ul>
                  <li class="global-footer__link-item" *ngFor="let link of links?.slice(3,6);">
                    <a href="{{ link?.url }}" [attr.aria-label]="'click here to go to ' + link.text" class="global-footer__link">
                      {{ link.text }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="global-footer__links--socials">
              <p>{{ socialTitle }}</p>
              <ul>
                <li class="global-footer__link-item" *ngFor="let link of socialLinks">
                  <a href="{{ link.url }}" [attr.aria-label]="link.icon" class="global-footer__link" target="_blank">
                    <i class="social__icon social__icon--{{ link.icon.toLocaleLowerCase() }}"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="global-footer__bottom">
        <div class="global-footer__copyright" [innerHTML]="copyright"></div>
        <div [ngClass]="{
          'global-footer__policies': 'policyLinks?.length < 3',
          'global-footer__policies--three': policyLinks?.length > 2
        }">
          <ul>
            <li class="global-footer__link-item" *ngFor="let link of policyLinks">
              <a href="{{ link.url }}" [attr.aria-label]="'click here to go to ' + link.text" class="global-footer__link">
                {{ link.text }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
