import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ScreeningComponent } from '@pages/planner/screening/screening.component';
import { CustomerActivityService } from '@services/planner/customer-activity.service';
import { RebaDataService } from '@services/planner/reba-data.service';
import { TodoStepHistoryService } from '@services/planner/todo-step-history.service';
import { UserService } from '@services/planner/user.service';
import { take } from 'rxjs/operators';
import { Screening } from 'src/app/typings/Screening';
import { AnalyzeStep } from '../analyze-your-budget.component';

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss']
})
export class TopicsComponent implements OnInit {

  @ViewChild(ScreeningComponent, {static: false}) screening: ScreeningComponent;
  ExpenseScenario = ExpenseScenario

  @Input() content: any;

  @Input() results: any;

  @Input() expenseQuestions: any[];
  @Input() incomeQuestions: any[];

  @Output() onEdit = new EventEmitter();

  public headers: {title: string}[]

  public rows: {item: string, code: string, annualExpense: number, nationalAverage: number, variance: number}[];

  public expenseScenario: ExpenseScenario;

  public scenarioText: any;

  public showScreening: boolean 

  public subScreening: Screening;

  public selectedGoals: Set<string>;

  public annualizedIncome: number;

  public totalAnnualExpense: number;
  public totalAverageExpense: number;
  public totalVariance: number;
  public netIncome: number;
  public isMobile: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = event.target.innerWidth <= 768;
  }

  constructor(private api: RebaDataService, private userService: UserService, private customerActivityService: CustomerActivityService, private step: TodoStepHistoryService) { 
    this.headers = [];
    this.rows = [];
    this.showScreening = false;
    this.expenseScenario = ExpenseScenario.NOT_CALCULATED;
    this.selectedGoals = new Set();
    this.annualizedIncome = 0;
    this.totalAnnualExpense = 0;
    this.totalAverageExpense = 0;
    this.totalVariance = 0;
    this.isMobile = window.innerWidth <= 768;
  }

  public editAnswers(){
    this.onEdit.emit();
  }

  async ngOnInit() {
    // build a lookup matrix

    this.headers = ["budget_column_label", "annualized_expense_column_label", "national_average_column_label", "variance_column_label"].map((key) => this.content[key].value)
  
    this.api.getScreening(this.content.screening_code.value, '23838', 'va', 'en').subscribe(async (screening) => {
      this.subScreening = screening;

      this.step.$setHistorySubject.subscribe((stepHistory) => {
        const newStep = stepHistory["analyze_your_budget"]
        this.onStepEvent(newStep)
      })
      this.results.subscribe((results) => {
        this._buildTable(this.subScreening, results)
      })
    })
  }

  private onStepEvent(step){
    if(!step || (step.step !== AnalyzeStep.BudgetResult && step.step !== AnalyzeStep.BudgetOverview)) return;
  
    this.scenarioText = step.scenario;

    if(step.step === AnalyzeStep.BudgetResult)  this.showScreening = false;
  }

  private async _buildTable(screening: Screening, results){
    this.rows = [];
    const matrix: ExpenseMatrix = {};
    const cadObj: any = {};

    for(const question of this.expenseQuestions){

      (matrix[question.expense_code.value]  as any) = (["label", "goals_question_code", "age_under_25", "age_25___34", "age_35___44", "age_45___54", "age_55___64", "age_65___74", "age_75_and_older", "expense_code"]
      .reduce((accum, val) => Object.assign(accum, {[val]: question[val].value}) , {}))  

    }

    const expensesResult = Object.entries(results['expenses']) as any[];
    const incomesResult = Object.entries(results['incomes']) as any[];

    let atleastOneNegativeVariance = false;

    this.expenseScenario = ExpenseScenario.ALL_POSITIVE;

    for(const [code, {amount, period}] of expensesResult){

      let annualized = 0;

      const coalescedAmount = (amount ?? 0)

      switch(period){
        case 'monthly':{
          annualized = coalescedAmount * 12;
          break;
        }
        case 'quarterly':{
          annualized = coalescedAmount * 4;
          break;
        }
        case 'annual':{
          annualized = coalescedAmount;
          break
        }
        default:{
          throw new Error(`Unknown period ${period}`)
        }
      }

      const nationalAverageLookup = matrix[code];

      // age group here:

      const nationalExpense = nationalAverageLookup[results['ageGroup']];

      const variance = nationalExpense - annualized;

      if(variance < 0){
        atleastOneNegativeVariance = true; 
      }
      
      cadObj[nationalAverageLookup.expense_code] = {variance, annualized}

      this.rows.push({
        item: nationalAverageLookup.label, 
        code: nationalAverageLookup.goals_question_code, 
        annualExpense: annualized, 
        nationalAverage: 
        nationalExpense, 
        variance
      })

      this.totalAnnualExpense += annualized;
      this.totalAverageExpense += nationalExpense;
      this.totalVariance += variance;

    }


    this.annualizedIncome = incomesResult.reduce((accum, [code, {amount, period}]) => {

        const coalescedAmount = amount ?? 0;
        let annualized = 0;

        switch(period){
          case 'monthly':{
            annualized = (coalescedAmount * 12);
            break;
          }
          case 'quarterly':{
            annualized = (coalescedAmount * 4);
            break;
          }
          case 'annual':{
            annualized = coalescedAmount;
            break;
          }
          default:{
            throw new Error(`Unknown period ${period}`)
          }
        }

        this.customerActivityService.updateActivity({[code]: annualized}).pipe(take(1))
      
        return accum + annualized
    }, 0)


    


    this.netIncome = this.annualizedIncome - this.totalAnnualExpense;

    this.customerActivityService.updateActivity({"net_annualized_income": this.netIncome}).pipe(take(1))

    if (this.scenarioText === 'questions_submitted_text'){
      return
    }

    if(!atleastOneNegativeVariance && this.netIncome < 0){
      this.expenseScenario = ExpenseScenario.POSITIVE_VARIANCE_NEGATIVE_NET_INCOME
      this.scenarioText = 'scenario_2_text';
      this.showScreening = true;
    }else if(atleastOneNegativeVariance && this.netIncome > 0){
      this.expenseScenario = ExpenseScenario.ATLEAST_ONE_NEGATIVE_VARIANCE_AND_POSITIVE_NET_INCOME
      this.scenarioText = 'scenario_3_text';
      this.showScreening = true;
    }else if(atleastOneNegativeVariance && this.netIncome < 0){
      this.expenseScenario = ExpenseScenario.ATLEAST_ONE_NEGATIVE_VARIANCE_AND_NEGATIVE_NET_INCOME
      this.scenarioText = 'scenario_4_text';
      this.showScreening = true;
    }else{
      this.scenarioText = 'scenario_1_text'
      this.expenseScenario = ExpenseScenario.ALL_POSITIVE
    }


    // check if scenario 3
    this.step.setHistoryStep("analyze_your_budget", {step: AnalyzeStep.BudgetOverview, scenario: this.scenarioText}, false)


    if(this.expenseScenario === ExpenseScenario.ALL_POSITIVE){
      return
    }

    // loop through rows to hide goals

     const question = this.getQuestionInScreening(screening, 'awp_goals');

      const options = question.answerGroup.answers;

      options.forEach(async (op) => {

        op['hide'] = !(await this.checkAnswers(op.value))
        
      })


      await this.customerActivityService.updateActivity({boa_annualized_expenses: cadObj}).pipe(take(1)).toPromise()


  }

  public getQuestionInScreening(screening: Screening, code: string){

    for(const section of screening.sections){
      for(const group of section.questionGroups){
        for(const question of group.questions){
          if(question.id === code){
            return question;
          }
        }
      }
    }
    return null;
  }

  public onScreeningSubmit($event){
    const lastText = this.scenarioText
    this.scenarioText = 'questions_submitted_text';
    this.step.setHistoryStep("analyze_your_budget", {step: AnalyzeStep.BudgetResult, scenario: this.scenarioText, lastScenario: lastText})
  }

  // checks for user's goals answers
  private async checkAnswers(code: string,): Promise<boolean>{
    let CADId = this.userService.CADId || '';
    const allAnswers = JSON.parse(localStorage.getItem('allAnswers') ?? '{}')[CADId] || {};

    if(!('awp_goals' in allAnswers)){

      if(this.userService.authSet()) {
        // if no value was received in local storage, check the CAD
  
          const questionResponse = await this.customerActivityService.getQuestionForID("awp_goals").pipe(take(1)).toPromise();
  
          const dataItems = questionResponse[0]; // gets the queried questionCode (empty object if questionCode is not in CAD)

          return Object.keys(dataItems).length !== 0 && !dataItems["awp_goals"].includes(code)
  
      }



      return true;
    }


    return !allAnswers['awp_goals'].includes(code);

  }


  public renderContent(content: string){

    return content?.split(/(\{.*?\})/g).map((token) => {

      if(token.startsWith('{') && token.endsWith("}")){

        switch(token){
          case '{expenses}':{
            let html = '<ul class="expenses-list">';
            for(const row of this.rows){

              if(row.variance < 0){
                html += 
                `
                <li>${row.item}</li>\n
                `
              }

            }

            html += '</ul>'
            return html;
          }
        }

      }
      return token;
    }).join('');



  }

}


export interface ExpenseMatrix
{[question: string]: {label, goals_question_code, expense_code, age_under_25, age_25___34, age_35___44, age_45___54, age_55___64, age_65___74, age_75_and_older}}


export enum ExpenseScenario{
  NOT_CALCULATED = 0,
  ALL_POSITIVE = 1,
  POSITIVE_VARIANCE_NEGATIVE_NET_INCOME = 2,
  ATLEAST_ONE_NEGATIVE_VARIANCE_AND_POSITIVE_NET_INCOME = 3,
  ATLEAST_ONE_NEGATIVE_VARIANCE_AND_NEGATIVE_NET_INCOME = 4

}