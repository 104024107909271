<div *ngIf="!data" class="loading-page">
  <img src="https://angular.ncoa.org/assets/images/loader.gif" alt="Loading" />
</div>

<age-well-planner *ngIf="data">
  <div class="subtopic subtopic--landing">
    <app-cm-banner> </app-cm-banner>
    <ncoa-vertical-landing-header
    *ngIf="data.subtopicHeader && !assessmentIsComplete"
    [title]="isSavedPage ? data.subtopicHeader.savedTitle : data.subtopicHeader.title  "
    [desc]="isSavedPage ? data.subtopicHeader.savedDesc : data.subtopicHeader.desc"
    [image]="data.subtopicHeader.image"
    [planSteps]="data.subtopicHeader.planSteps"
   ></ncoa-vertical-landing-header>

   <ncoa-vertical-assessment
   #verticalAssessment
   *ngIf="data.assessment"
   [assessmentCode]="data.assessment.assessmentCode"
   [pageData]="data"
   ></ncoa-vertical-assessment>

  </div>
</age-well-planner>
