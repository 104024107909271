import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccordianComponent } from '@components/accordian/accordian.component';
import { KontentDeliveryService } from '@services/kontent-delivery.service';
import { CustomerActivityService } from '@services/planner/customer-activity.service';
import { QuestionService } from '@services/planner/question.service';
import { TodoStepHistoryService } from '@services/planner/todo-step-history.service';
import { UserService } from '@services/planner/user.service';
import { take } from 'rxjs/operators';
import { Question } from 'src/app/typings/Screening';
import { AmountQuestionComponent } from './amount-question/amount-question.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-analyze-your-budget',
  templateUrl: './analyze-your-budget.component.html',
  styleUrls: ['./analyze-your-budget.component.scss']
})
export class AnalyzeYourBudgetComponent implements OnInit {
  AnalyzeStep = AnalyzeStep
  public widgetContent: any;

  @Output() hideContent: EventEmitter<boolean>;

  @ViewChildren(AccordianComponent) accordians: QueryList<AccordianComponent>;
  @ViewChildren(AmountQuestionComponent, { read: ElementRef }) amountQuestions: QueryList<ElementRef>;

  public answers = new BehaviorSubject<any>({'expenses': {}, 'incomes': {}, 'ageGroup': null});
  public budgetFormGroup: FormGroup;

  public expandedAll: boolean;

  public submitted: boolean;
  public valid: boolean;

  public expenseQuestions: any[];
  public incomeQuestions: any[];

  public ageGroupQuestion: Question


  constructor(private fb: FormBuilder, private kentico: KontentDeliveryService, private user: UserService, private cas: CustomerActivityService, public stepService: TodoStepHistoryService, private _elementRef : ElementRef) { 
    this.expandedAll = false;
    this.submitted = false;
    this.valid = false;
    this.hideContent = new EventEmitter();
  }

  ngOnInit() {

    this.kentico.getItemAndCache("budget_calculator_widget").then(async ({item, modular_content}) => {
      this.widgetContent = item.elements;

      this.expenseQuestions = item.elements.expenses.value.map((exp) => modular_content[exp].elements);
      this.incomeQuestions = item.elements.incomes.value.map((exp) => modular_content[exp].elements);

      const expenseCodes = this.expenseQuestions.map(q => q.expense_code.value);
      const incomeCodes = this.incomeQuestions.map(q => q.cad_field.value)


      this.budgetFormGroup = this.fb.group({
        'expenses': this._buildGroup(expenseCodes),
        'incomes': this._buildGroup(incomeCodes),
        'ageGroup': this.fb.control(null, {validators: [Validators.required]}), 
      })

      this._getAnswersToExpenses();

      const step = await this.stepService.getStep("analyze_your_budget");


      if(!step){
        this.stepService.setHistoryStep("analyze_your_budget", {step: AnalyzeStep.Expenses, scenario: 'scenario_1_text'})
        return;
      }

      switch(step.step){
        case AnalyzeStep.Expenses:{
          this.submitted = false;
          this.valid = false;
          this.hideContent.emit(false)
          break;
        }
        case AnalyzeStep.BudgetResult:
        case AnalyzeStep.BudgetOverview:{
          this.submitted = true;
          this.valid = true;
          this.hideContent.emit(true)
          break;
        }
      }

      
    })


  
  }

  private async _getAnswersToExpenses(){
    // console.log('getting answers');
    let answers = undefined;
    const answerJson = localStorage.getItem('boa_expense_answers');
    if (answerJson) {
      // console.log('getting answers from local storage');
      answers = JSON.parse(answerJson);
      // console.log('answers: ', this.answers);
    } else if (this.user.authSet()) {
      // console.log('getting answers from api');
      const expenses = await this.cas.getQuestionForID("expenses").pipe(take(1)).toPromise();
      const incomes = await this.cas.getQuestionForID("incomes").pipe(take(1)).toPromise();
      const ageGroup = await this.cas.getQuestionForID("ageGroup").pipe(take(1)).toPromise();      
      answers = {
        ...expenses[0],
        ...incomes[0],
        ...ageGroup[0], 
      };
      // console.log('answers: ', this.answers);
      // save answers to local storage
      localStorage.setItem("boa_expense_answers", JSON.stringify(answers))
    } else {
      return
    }    

    this.answers.next(answers);
    this.budgetFormGroup.setValue(answers)
  }

  public async onSubmit(){
    this.cas.announceAnalyzeBudgetEdited().pipe(take(1)).subscribe();
    if (this.user.authSet()) {
      localStorage.setItem("changed_budget", "true");
    }
    this.submitted = true;
    if(this.budgetFormGroup.valid){
      window.scrollTo(0, 0)
      this.valid = true;
      this.hideContent.emit(true)
      this.stepService.setHistoryStep("analyze_your_budget", {step: AnalyzeStep.BudgetOverview})

      const values = this.budgetFormGroup.getRawValue();
      this.answers.next(values);


      await this.cas.updateActivity(values).pipe(take(1)).toPromise()
      

      localStorage.setItem("boa_expense_answers", JSON.stringify(values))

    }
  }

  public onPrev(){
    window.scrollTo(0, 0)

    this.stepService.getStep("analyze_your_budget").then((step) => {
      if(!step){
        return;
      }


      if(step.step === AnalyzeStep.BudgetResult){
        return this.stepService.setHistoryStep("analyze_your_budget", {step: AnalyzeStep.BudgetOverview, scenario: step.lastScenario ?? step.scenario})
      }

      this.stepService.setHistoryStep("analyze_your_budget", {step: AnalyzeStep.Expenses})
      this.submitted = false;
      this.valid = false;
      this.hideContent.emit(false)
    });
  
  }

  public onExpandAllExpenses(){
    this.expandedAll = !this.expandedAll;

    this.accordians.toArray().slice(1).forEach((accordian) => {
      this.expandedAll ? accordian.expand() : accordian.collapse();
    })

  }


  private _buildGroup(fields: string[]){
    return this.fb.group({
      ...fields.reduce((accum, val) => Object.assign(accum, {[val]: this._getAmountGroup()}), {})
   })
  }

  private _getAmountGroup(){
    return this.fb.group({
      'amount': this.fb.control(null),
      'period': this.fb.control('monthly', [Validators.required])
    })
  }


  public get expenses(){
    return this.budgetFormGroup.get('expenses')
  }

  public get incomes(){
    return this.budgetFormGroup.get('incomes')
  }

  public onEdit(){
    this.valid = false;
    setTimeout(() => {

      const offset = -115;

      const element = this.amountQuestions.first.nativeElement;

      const scroll = element.getBoundingClientRect().top + window.pageYOffset + offset;


      window.scrollTo({top: scroll})

    }, 100)
  }

}


export enum AnalyzeStep{
  Expenses = 1,
  BudgetOverview = 2,
  BudgetResult = 3
}