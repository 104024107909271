<age-well-planner *ngIf="data">
  <div class="assessment-breakdown">
    <ncoa-breakdown-header
      *ngIf="data.breakdownHeader"
      [id]="data.breakdownHeader.id"
      [codename]="data.breakdownHeader.codename"
      [isSaved]="data.breakdownHeader.isSaved"
      [backLink]="data.breakdownHeader.backLink"
      [profileLink]="data.breakdownHeader.profileLink"
      [headline]="data.breakdownHeader.headline"
      [variationType] = '"retirement"'
    ></ncoa-breakdown-header>

    <ncoa-breakdown-graph
      *ngIf="data.breakdownGraph"
      [headline]="data.breakdownGraph.headline"
      [body]="data.breakdownGraph.body"
      [twoColBreakdown]="data.breakdownGraph.twoColBreakdown"
      [chartData]="data.breakdownGraph.chartData"
      [userIsWorking]="data.breakdownGraph.userIsWorking"
    ></ncoa-breakdown-graph>

    <ncoa-breakdown-partnership
      *ngIf="data.breakdownPartnership"
      [text]="data.breakdownPartnership.text"
      [icon]="data.breakdownPartnership.icon"
    ></ncoa-breakdown-partnership>

    <ncoa-vertical-recommended
      *ngIf="data.recommended"
      [heading]="data.recommended.heading"
      [intro]="data.recommended.intro"
      [cards]="data.recommended.cards"
      [location]="data.recommended.location"
      (onLoadMoreClick) = 'onLoadMoreClick()'
    ></ncoa-vertical-recommended>
  </div>
</age-well-planner>

<div class="loading-page" *ngIf="!data">
  <img src="https://angular.ncoa.org/assets/images/loader.gif" alt="Loading" />
</div>
