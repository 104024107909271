<ncoa-courses
  [isOpen]="true"
  [heading]="courseHeading"
  [coursesData]="coursesData"
></ncoa-courses>

<div class="component component--map">
  <div class="wrapper">
    <div class="map">
      <div class="map--header">
        <h4 class="map--eyebrow" *ngIf="eyebrow">{{ eyebrow }}</h4>
        <h3 class="map--heading" [innerHTML]="title"></h3>
        <p *ngIf="description">{{ description }}</p>
      </div>

      <div class="map--container">
        <div class="map--wrapper">

          <div class="list--header__search">
            <div class="input--wrapper">
              <form (ngSubmit)="onSubmit()">
              <input
                type="text"
                name="input--keyword"
                placeholder="City or Zip Code"
                [(ngModel)]="searchKey"
                aria-label="Search City or Zip Code"
              >
              <button type="submit">Search</button>
              </form>
            </div>
          </div>

          <div class="google-map--wrapper">
            <div [ngClass]="isReady ? 'ready' : ''" class="google-map" #googleMapElement></div>
            <div class="google-map__buttons" [ngClass]="resultCount > 0 ? 'up' : ''">
              <button type="button" aria-label="Zoom In" (click)="updateZoom('in')"><span>+</span></button>
              <button type="button" aria-label="Zoom Out" (click)="updateZoom('out')"><span>-</span></button>
            </div>
            <div #loadingElement class="loader-overlay" aria-hidden="true">
              <img src="https://angular.ncoa.org/assets/images/loader.gif" alt="Loading..." />
            </div>
          </div>
        </div>
        <div class="map--listing is-active">
          <div class="map--listing__header">
            <div class="list--header">
              <div class="list--header__search">
                <div class="input--wrapper">
                  <form (ngSubmit)="onSubmit()">
                    <input type="text" name="input--keyword" class="js--search-filter" placeholder="City or Zip Code" [(ngModel)]="searchKey" aria-label="Search City or Zip Code">
                    <button type="submit" class="js--search-filter__trigger">Search</button>
                  </form>
                </div>
              </div>
              <div class="list--header__panel">
                <h3><span>{{ resultCount }}</span> Search Result{{ resultCount != 1 ? 's' : '' }}</h3>
                <a *ngIf="!isFilterOpen && resultCount > 0" href="javascript: window.print();" class="js--print">
                  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.13793 17.9996V15.3378C4.13793 13.3083 5.82759 11.678 7.93103 11.678H15.8621V17.9996H4.13793ZM1.68966 14.007H2.41379V11.3452C2.41379 10.7796 2.89655 10.3471 3.44828 10.3471H16.5517C17.1379 10.3471 17.5862 10.8129 17.5862 11.3452V14.007H18.3103C19.2414 14.007 20 13.275 20 12.3767V6.38775C20 5.09015 18.8966 3.99219 17.5172 3.99219H2.48276C1.13793 3.99219 0 5.05688 0 6.38775V12.3767C0 13.2417 0.758621 14.007 1.68966 14.007Z" fill="#0B4A5D"/>
                    <path d="M4.13867 2.66174C4.13867 1.19778 5.38005 0 6.89729 0H15.8628V2.66174H4.13867Z" fill="#0B4A5D"/>
                  </svg>
                  Print Results
                </a>
              </div>
              <div class="list--header__panel">
                <button type="button" class="js--filter" (click)="toggleFilters()">{{ filterToggleText }}</button>
              </div>
            </div>
          </div>

          <div class="map--listing__filter" [ngClass]="isFilterOpen ? 'is-active' : ''">
            <div class="filter-group" *ngFor="let group of filterOptions; let i = index">
              <div class="field {{ group.isChecked ? 'checked' : '' }}">
                <label [for]="group.value">{{ group.name }}</label>
                <input
                  [checked]="group.isChecked"
                  type="checkbox"
                  name="option"
                  [value]="group.value"
                  [id]="group.value"
                  (change)="onChange(i)"
                  class="js--filter-option"
                  (focus)="onFocus($event.target)"
                  (blur)="onBlur($event.target)"
                />
                <span class="rect">
                  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.09961 13.166C6.45117 13.5176 7.04883 13.5176 7.40039 13.166L17.7363 2.83008C18.0879 2.47852 18.0879 1.88086 17.7363 1.5293L16.4707 0.263672C16.1191 -0.0878906 15.5566 -0.0878906 15.2051 0.263672L6.76758 8.70117L2.79492 4.76367C2.44336 4.41211 1.88086 4.41211 1.5293 4.76367L0.263672 6.0293C-0.0878906 6.38086 -0.0878906 6.97852 0.263672 7.33008L6.09961 13.166Z" fill="#0B4A5D"/>
                  </svg>
                </span>
              </div>

              <div class="filter-group__sub">
                <div class="field {{ option.isChecked ? 'checked' : '' }}" *ngFor="let option of group.options; let subindex = index">
                  <label [for]="option.value">{{ option.name }}</label>
                  <input
                    [checked]="option.isChecked"
                    type="checkbox"
                    name="option"
                    [value]="option.value"
                    [id]="option.value"
                    (change)="onChange(i, subindex)"
                    class="js--filter-option"
                    (focus)="onFocus($event.target)"
                    (blur)="onBlur($event.target)"
                  />
                  <span class="rect">
                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.09961 13.166C6.45117 13.5176 7.04883 13.5176 7.40039 13.166L17.7363 2.83008C18.0879 2.47852 18.0879 1.88086 17.7363 1.5293L16.4707 0.263672C16.1191 -0.0878906 15.5566 -0.0878906 15.2051 0.263672L6.76758 8.70117L2.79492 4.76367C2.44336 4.41211 1.88086 4.41211 1.5293 4.76367L0.263672 6.0293C-0.0878906 6.38086 -0.0878906 6.97852 0.263672 7.33008L6.09961 13.166Z" fill="#0B4A5D"/>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            class="map--listing__result"
            [ngClass]="!isFilterOpen ? 'is-active' : ''"
          >
            <div class="listing--result no--result" *ngIf="resultCount == 0">
              <p>Search for NCOA partners nationwide.</p>
            </div>

            <div class="result--listing" *ngIf="resultCount > 0">
              <div (mouseenter)="mouseEnter(i)" (mouseleave)="mouseLeave()" class="listing--result" *ngFor="let result of listing; let i = index" >
                <h3 class="listing--result__partner">{{ result.name }}</h3>
                <p class="listing--result__location">
                  <span class="listing--result__address">{{ result.address }} </span>
                  <span class="listing--result__city" *ngIf="result.city">{{ result.city }} </span><span class="listing--result__state" *ngIf="result.state">, {{ result.state }} </span><span class="listing--result__zip" *ngIf="result.zip_code"> {{ result.zip_code }}</span>
                  <span class="listing--result__phone" *ngIf="result.phone_number">Phone: {{ result.phone_number }} </span>
                  <span class="contact-info" *ngIf="result.contact_info != null" [innerHTML]="result.contact_info"></span>
                </p>

                <ul role="list" class="listing--result__category">
                  <li role="listitem" *ngFor="let activity of result.result_activities">
                    <span class="activity">{{ activity }}</span>
                  </li>
                  <li role="listitem" *ngFor="let activity of result.parent_activities">
                    <span>
                      <i *ngIf="activity.codename === 'healthy_living'">
                        <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M16.4082 2.08546H17.6308C17.473 1.84884 17.2758 1.61221 17.0392 1.41503C16.842 1.21784 16.6448 1.06009 16.4082 0.902344V2.08546Z" fill="#FA6264"/>
                          <path d="M18.3815 3.86008V3.97839C18.3815 4.29389 18.1055 4.56995 17.79 4.56995H16.4097V5.95025C16.4097 6.26575 16.1336 6.54181 15.8181 6.54181H14.5561C14.2406 6.54181 13.9646 6.26575 13.9646 5.95025V4.56995H12.5843C12.2688 4.56995 11.9927 4.29389 11.9927 3.97839V2.7164C11.9927 2.4009 12.2688 2.12484 12.5843 2.12484H13.9646V0.705105C13.9646 0.468482 14.1223 0.231859 14.3589 0.152984C12.8603 -0.00476438 11.3223 0.468482 10.1786 1.61216L9.78423 2.00653L9.38986 1.61216C7.45744 -0.280825 4.38134 -0.438573 2.44892 1.41497C0.516495 3.30796 0.477058 6.38406 2.37004 8.31648L9.1138 15.0602C9.4293 15.3757 9.98142 15.3757 10.2969 15.0602L17.0407 8.31648C18.3027 7.09393 18.7365 5.39813 18.3815 3.86008Z" fill="#FA6264"/>
                        </svg>
                      </i>
                      <i *ngIf="activity.codename === 'economic_security'">
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.57617 9.01367V11.3775C9.42407 11.2799 10.0047 10.8482 10.0047 10.2059C10.0047 9.58407 9.38809 9.26033 8.57617 9.01367Z" fill="#0ED882"/>
                          <path d="M6.07422 6.01122C6.07422 6.53023 6.67545 6.78717 7.49251 7.01841V5.06055C6.6292 5.13763 6.07422 5.5179 6.07422 6.01122Z" fill="#0ED882"/>
                          <path d="M7.9702 0.318359C3.56629 0.318359 0 3.88465 0 8.28856C0 12.6925 3.56629 16.2588 7.9702 16.2588C12.3741 16.2588 15.9404 12.6925 15.9404 8.28856C15.9404 3.88465 12.3741 0.318359 7.9702 0.318359ZM8.57657 12.8672V14.1108H7.4923V12.8672C5.69374 12.6925 4.40391 11.7469 4.19322 10.3543L5.79137 9.65033C5.81707 10.5239 6.50566 11.1406 7.4923 11.3358V8.71508C5.99178 8.31939 4.37822 7.82607 4.37822 6.13029C4.37822 4.62977 5.63207 3.69452 7.4923 3.59174V2.46122H8.57657V3.63285C10.0411 3.81785 11.0894 4.54241 11.48 5.55475L9.93834 6.3307C9.82529 5.73974 9.33711 5.30809 8.57144 5.13851V7.29164C10.0976 7.69761 11.7472 8.26287 11.7472 10.0768C11.7523 11.6493 10.5087 12.7233 8.57657 12.8672Z" fill="#0ED882"/>
                        </svg>
                      </i>
                      <i *ngIf="activity.codename === 'senior_centers'">
                        <svg width="12" height="26" viewBox="0 0 18 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3.87013 0C1.74026 0 0 1.7045 0 3.79061V26H18V0H3.87013ZM8.02597 20.4795H2.44156V16.4344H8.02597V20.4795ZM8.02597 13.3053H2.44156V9.43836H8.02597V13.3053ZM8.02597 6.48728H2.44156V3.76517C2.44156 3.0274 3.03896 2.44227 3.79221 2.44227H8.02597V6.48728ZM15.7662 20.4795H10.1818V16.4344H15.7662V20.4795ZM15.7662 13.3053H10.1818V9.43836H15.7662V13.3053ZM15.7662 6.48728H10.1818V2.44227H15.7662V6.48728Z" fill="#0B4A5D"/>
                        </svg>
                      </i>
                      {{ activity.name }}
                    </span>
                  </li>
                </ul>

                <div><a href="javascript: void(0);" *ngIf="result.amp_courses" (click)="openModal( result.amp_courses, result.name )" class="listing--result__link"><span>View Amp Courses</span></a></div>
                <div *ngIf="result.website"><a href="{{ result.website }}" *ngIf="result.website != ''" class="listing--result__link" target="_blank"><span>View Website</span></a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
