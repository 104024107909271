<div class="temporary-landing">
  <div class="temporary-landing__accent" aria-hidden="true"></div>
  <div class="temporary-landing__content">
    <div class="temporary-landing__hero">
      <div class="temporary-landing__body">
        <div class="temporary-landing__header">
          <img src="http://angular.ncoa.org/assets/images/logo.svg" alt="NCOA Logo" class="temporary-landing__logo">
          <p class="temporary-landing__header-text">Coming Soon</p>
        </div>
        <div class="temporary-landing__text">
          <h2 class="temporary-landing__heading">Our Mission</h2>
          <p class="temporary-landing__paragraph">Since 1950, our mission has been to improve the lives of older adults. Our goal is to improve the health and economic security of 40 million older adults by 2030.</p>
        </div>
      </div>
      <div class="temporary-landing__image">
        <img src="http://angular.ncoa.org/assets/images/temporary-page-image.png" alt="Background image">
      </div>
    </div>
    <div class="temporary-landing__footer">
      <div class="temporary-landing__social temporary-landing__social--desktop">
        <div class="temporary-landing__social-heading">Find us on Social</div>
        <div class="temporary-landing__social-links">
          <a
            *ngFor="let social of socials"
            class="temporary-landing__link temporary-landing__link--{{ social.type }}"
            href="{{ social.url }}">
          </a>
        </div>
      </div>
      <div class="temporary-landing__ctas">
        <a href="https://www.ncoa.org/get-involved/sign-up/" class="temporary-landing__cta temporary-landing__cta--light">
          Sign up for our newsletter
        </a>
        <a href="https://www.ncoa.org/" class="temporary-landing__cta temporary-landing__cta--dark">
          Go to NCOA.org
        </a>
      </div>
      <div class="temporary-landing__social temporary-landing__social--mobile">
        <div class="temporary-landing__social-heading">Find us on Social</div>
        <div class="temporary-landing__social-links">
          <a
            *ngFor="let social of socials"
            class="temporary-landing__link temporary-landing__link--{{ social.type }}"
            [attr.aria-label]="'click to visite NCOA\'s '+ social.type +' page'"
            href="{{ social.url }}"
            target="_blank">
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
