<div class="component component--newsletter" [ngClass]="isLoading ? 'is-loading' : ''">
  <div class="component__container">
    <picture aria-hidden="true">
      <source srcset="{{background}}" media="(min-width: 1024px)" />
      <source srcset="{{mobilebackground}}" media="(min-width: 0px)" />
      <img src="{{background}}" alt="{{backgroundCaption}}" loading="lazy" width="{{width}}" height="{{height}}"/>
    </picture>
    <div class="component__wrapper component-spacing">
      <div class="newsletter {{ isError === true ? 'is-error' : '' }}">
        <div class="wrapper">
          <div class="newsletter--wrapper">
            <div class="newsletter--content">
              <ng-container *ngIf="isSubmitted === false">
                <h2 class="newsletter--heading" [innerHTML]="heading"></h2>
                <div class="newsletter--description" [innerHTML]="description"></div>
              </ng-container>

              <ng-container *ngIf="isSubmitted === true">
                <h2 class="newsletter--heading">You’re all signed up!</h2>
                <p class="newsletter--description">You’ll receive important updates from NCOA about
                  programs, benefits, and services.</p>
              </ng-container>

            </div>

            <div *ngIf="!isSubmitted" class="form">

              <div class="form__name-field" [ngClass]="firstName.status.code">
                <label for="firstName" style="display: none;">First Name</label>
                <input class="name" [(ngModel)]="firstName.value" type="text" name="firstName" id="firstName" [placeholder]="'First Name'"
                  (change)="onEmailChange($event)" (input)="onEmailInput($event)" [ngClass]="firstName.status.code" required />
                  <div *ngIf="firstName.status.code === 'error'" class="error">
                    {{ firstName.status.message }}
                  </div>
              </div>
                <div class="form__name-field" [ngClass]="lastName.status.code">
                  <label for="lastName" style="display: none;">Last Name</label>
                  <input  class="name"  [(ngModel)]="lastName.value" type="text" name="lastName" id="lastName" [placeholder]="'Last Name'"
                    (change)="onEmailChange($event)" (input)="onEmailInput($event)" [ngClass]="lastName.status.code" required />
                    <div *ngIf="lastName.status.code === 'error'" class="error">
                      {{ lastName.status.message }}
                    </div>
                </div>
            
              <div class="form__select">
                <label for="newsletterRole" style="display: none;">{{dropdownTitle}}</label>
              <select [(ngModel)]="newsletterRole" name="newsletterRole" id="newsletterRole">
                <option *ngFor="let option of rolesOptions" [value]="option.value">{{option.label}}</option>
              </select>
            </div>
            <div class="form__email" [ngClass]="newsletterEmail.status.code">
              <div class="input-group">
                <label for="email" style="display: none;">Email</label>
                <input
                  [(ngModel)]="newsletterEmail.value"
                  type="email"
                  name="email"
                  id="email"
                  [placeholder]="emailPlaceHolder"
                  (change)="onEmailChange($event)"
                  (input)="onEmailInput($event)"
                  [ngClass]="newsletterEmail.status.code"
                  required/>
                <input type="button" class="newsletter--email__submit" (click)="onSubmit($event)" [value]="emailButtonCTAText"/>
              </div>
              <div *ngIf="newsletterEmail.status.code === 'error'" class="error">
                {{ newsletterEmail.status.message }}
              </div>
            </div>
            
    
          </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading-screen">
      <img height="180px" width="180px" src="https://angular.ncoa.org/assets/images/loader.gif" alt="loading" loading="lazy"/>
    </div>
  </div>
</div>
