<div *ngIf='dismissed === false' id="privacy-notice" role="dialog" aria-modal="false" aria-live="polite" aria-labelledby="privacy-notice-heading"
  class="dialog_dialog__IOow4 dialog_dialogBannerLayout__giDPT dialog_isOpen__c4Wq_ dialog_pinBottom__0TKfo">
  <div class="dialog_wrapper__AuvKB">
    <div class="dialog_container__eE9sR">
      <h2 class="dialog_heading__6dVKO" id="privacy-notice-heading">This site uses cookies.</h2>
      <div class="dialog_message__UkKI1">
        <p>We use cookies to give you the best experience on our website. For more information on what this means and
          how we use your data, please see our <a href="/page/privacy-policy">Privacy Policy</a>.</p>
      </div>
    </div><button (click)="dismiss()"
      class="dialog_cta__ATQPw buttonCta_buttonSizeMedium__QtIRK buttonCta_buttonThemeDefault__apeNs buttonCta_isFullWidthOnMobile__gbLo0"
      type="button">Accept</button>
    <div class="dialog_close__p8QV1"><button type="button" (click)="dismiss()"><span><svg xmlns="http://www.w3.org/2000/svg" width="22"
            height="22" fill="none">
            <path fill="currentColor"
              d="M20.892 3.324a1.567 1.567 0 1 0-2.216-2.216L11 8.784 3.324 1.108a1.567 1.567 0 1 0-2.216 2.216L8.784 11l-7.676 7.676a1.567 1.567 0 1 0 2.216 2.216L11 13.216l7.676 7.676a1.567 1.567 0 1 0 2.216-2.216L13.216 11z">
            </path>
          </svg></span>Close</button></div>
  </div>
</div>