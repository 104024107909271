<div class="profile-panel" [id]="panelID">
  <div class="profile-panel__head">
    <h2 class="profile-pane__headline">Edit {{ headline }} Info</h2>
    <button
      type="button"
      class="save-btn"
      (click)="clickSave()"
      [disabled]="saveButtonDisabled"
    >
      {{ saveCopy }}
    </button>
  </div>
  <div class="profile-panel__body">
    <ng-container *ngFor="let question of questions; let qIndex = index">
      <div class="field" *ngIf="question.displayQuestion">
        <span
          class="optional"
          *ngIf="
            !question.answerGroup?.validation ||
            (question.answerGroup.validation && !question.answerGroup.validation?.isRequired) ||
            (question.answerGroup.validation && question.answerGroup.validation.isRequired === false)"
        >
          [Optional]
        </span>
        <label class="label-text question" [for]="question.id">{{ question.text? question.text: question.label }}</label>
        <div class="hint" *ngIf="question.hasOwnProperty('hint')">{{ question.hint }}</div>
        <div [id]="'field-' + question.id" class="field__container" [ngSwitch]="question.answerGroup.answerType">
          <!-- select -->
          <div class="field__container--select" *ngSwitchCase="'select'">
            <div class="dropdown-container">
              <select
                [ngClass]="question.error ? 'error' : ''"
                [name]="question.id"
                [id]="question.id"
                [(ngModel)]="question.userAnswer"
                (change)="onChange(question.id)"
              >
                <option
                  [value]="answer.value"
                  *ngFor="let answer of question.answerGroup.answers"
                >
                  {{ answer.text }}
                </option>
              </select>
              <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.67406 7.70909C7.48101 7.90303 7.23967 8 6.99835 8C6.75703 8 6.51571 7.90303 6.32265 7.70909L0.289587 1.67273C-0.096529 1.28485 -0.096529 0.678788 0.289587 0.290909C0.675703 -0.0969697 1.27901 -0.0969697 1.66513 0.290909L6.99835 5.82562L12.3557 0.315152C12.7418 -0.0727268 13.3451 -0.0727268 13.7315 0.315152C14.0935 0.678788 14.0935 1.28485 13.7071 1.67273L7.67406 7.70909Z" fill="#0B4A5D"/>
              </svg>
            </div>
            <div *ngIf="question.error" class="error-message">
              {{ question.error }}
            </div>
          </div>
          <!-- end select -->

          <!-- short text -->
          <div class="field__container--short-text" *ngSwitchCase="'shortText'">
            <input
              class="input-text"
              [type]="question.answerGroup.validation && question.answerGroup.validation.hasOwnProperty('isEmail') && question.answerGroup.validation.isEmail === true ? 'email' : 'text'"
              [name]="question.id"
              [id]="question.id"
              [(ngModel)]="question.userAnswer"
              [ngClass]="question.error ? 'error' : ''"
              (change)="onShortTextChange(qIndex, question.id)"
            />
            <div *ngIf="question.error" class="error-message">
              {{ question.error }}
            </div>
          </div>
          <!-- end short text -->

          <!-- string text -->
          <div class="field__container--string-text" *ngSwitchCase="'string'">
            <input
              class="input-text"
              [type]="question.answerGroup.validation.hasOwnProperty('isEmail') && question.answerGroup.validation.isEmail === true ? 'email' : 'text'"
              [name]="question.id"
              [id]="question.id"
              [(ngModel)]="question.userAnswer"
              [ngClass]="question.error ? 'error' : ''"
              (change)="onShortTextChange(qIndex, question.id)"
            />
            <div *ngIf="question.error" class="error-message">
              {{ question.error }}
            </div>
          </div>
          <!-- end string text -->

          <!-- number text -->
          <div class="field__container--number-text" *ngSwitchCase="'number'">
            <input
              class="input-text"
              type="number"
              [name]="question.id"
              [id]="question.id"
              [(ngModel)]="question.userAnswer"
              [ngClass]="question.error ? 'error' : ''"
              (change)="genericOnChange()"
            />
            <div *ngIf="question.error" class="error-message">
              {{ question.error }}
            </div>
          </div>
          <!-- end number text -->

          <!-- boolean -->
          <div class="field__container--radio" *ngSwitchCase="'boolean'">
            <div class="radio-container" *ngFor="let answer of question.answerGroup.answers">
              <input
                type="radio"
                [name]="question.id"
                [id]="question.id + '-' + answer.value"
                [value]="answer.value"
                [(ngModel)]="question.userAnswer"
                (change)="onChange(question.id)"
              />
              <label [for]="question.id + '-' + answer.value" class="radio-text">{{ answer.text }}</label>
            </div>
            <div *ngIf="question.error" class="error-message">
              {{ question.error }}
            </div>
          </div>
          <!-- end boolean -->

          <!-- multi select -->
          <div class="field__container--checkbox" *ngSwitchCase="'multiSelect'">
            <div class="checkbox-wrapper" *ngFor="let answer of question.answerGroup.answers">
              <div class="checkbox-wrapper__inner">
              <input
                type="checkbox"
                [name]="question.id+ '-' + answer.value"
                [id]="question.id + '-' + answer.value"
                [value]="answer.value"
                [(ngModel)]="answer.isSelected"
                [attr.aria-checked]="answer.isSelected"
                (change)="checkboxChange(qIndex, answer)"
              />
              <svg *ngIf="answer.isSelected && answer.isSelected === true" width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.09961 13.166C6.45117 13.5176 7.04883 13.5176 7.40039 13.166L17.7363 2.83008C18.0879 2.47852 18.0879 1.88086 17.7363 1.5293L16.4707 0.263672C16.1191 -0.0878906 15.5566 -0.0878906 15.2051 0.263672L6.76758 8.70117L2.79492 4.76367C2.44336 4.41211 1.88086 4.41211 1.5293 4.76367L0.263672 6.0293C-0.0878906 6.38086 -0.0878906 6.97852 0.263672 7.33008L6.09961 13.166Z" fill="#0B4A5D"/>
              </svg>
              </div>
            <label [for]="question.id + '-' + answer.value">
              {{ answer.text }}
            </label>
            </div>
            <div *ngIf="question.error" class="error-message">
              {{ question.error }}
            </div>
          </div>
          <!-- end multi select -->

          <!-- drug search -->
          <div class="field__contianer--drug-search" *ngSwitchCase="'drugSearch'">
            <div *ngIf="question.userAnswer" class="prescriptions">
              <ul>
                <li *ngFor="let prescription of question.userAnswer; let prescriptionIndex = index;">
                  <div class="prescription-container">
                    <div>
                      <p>{{ prescription.name }} - {{ prescription.strength }} {{ prescription.form }}</p>
                      <p>Quantity: {{ prescription.quantity }} | Frequency: {{ prescription.frequency }}</p>
                    </div>
                    <button type="button" (click)="removeSelectedPrescription(qIndex, prescriptionIndex)">Clear</button>
                  </div>
                </li>
              </ul>
            </div>
            <div *ngIf="!preSelectedPrescription" class="find-prescription">
              <input
                type="text"
                class="input-text"
                [name]="question.id"
                [id]="question.id"
                (input)="onPrescriptionInput($event)"
              />
              <div class="prescription-selection" *ngIf="prescriptionSelection">
                <ul>
                  <li *ngFor="let option of prescriptionSelection">
                    <button type="button" (click)="clickPrescription(option)">
                      {{ option.name }} = {{ option.strength }} {{ option.form }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div *ngIf="preSelectedPrescription" class="complete-prescription">
              <div class="prescription">
                <span>{{ preSelectedPrescription.name }}</span>
                <button type="button" (click)="removePreSelectedPrescription()">Clear</button>
              </div>
              <div class="details">
                <div class="subfield">
                  <label for="quantity">Enter Quantity</label>
                  <input
                    type="text"
                    name="quantity"
                    id="quantity"
                    [(ngModel)]="prescriptionQuantity"
                  />
                </div>
                <div class="subfield">
                  <label for="days">Enter Refill Frequency</label>
                  <div class="dropdown-container">
                    <select name="days" id="days" [(ngModel)]="prescriptionFrequency">
                      <option value="30">Once a month</option>
                      <option value="60">Every 2 Months</option>
                      <option value="90">Every 3 Months</option>
                      <option value="182">Every 6 Months</option>
                      <option value="365">Every 12 Months</option>
                    </select>
                    <svg viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.57777 6.74545C6.41229 6.91515 6.20543 7 5.99859 7C5.79174 7 5.5849 6.91515 5.41942 6.74545L0.248217 1.46364C-0.0827392 1.12424 -0.0827392 0.593939 0.248217 0.254545C0.579174 -0.0848485 1.0963 -0.0848485 1.42725 0.254545L5.99859 5.09741L10.5906 0.275758C10.9216 -0.063636 11.4387 -0.063636 11.7698 0.275758C12.0801 0.59394 12.0801 1.12424 11.749 1.46364L6.57777 6.74545Z" fill="#0B4A5D"/>
                    </svg>
                  </div>
                  <!-- <input
                    type="text"
                    name="days"
                    id="days"
                    [(ngModel)]="prescriptionFrequency"
                  /> -->
                </div>
              </div>
              <button
                type="button"
                class="complete-btn"
                (click)="savePrescription(qIndex)"
              >
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.98642 0V5.98642H0V8.01357H5.98642V14H7.9819V8.01357H13.9683V5.98642H7.9819V0H5.98642Z" fill="white"/>
                </svg>
                Add Prescription
              </button>
            </div>
          </div>
          <!-- end drug search -->
        </div>
      </div>
    </ng-container>
    <button
      type="button"
      class="save-btn"
      (click)="clickSave()"
      [disabled]="saveButtonDisabled"
    >
      {{ saveCopy }}
    </button>
  </div>
</div>

<div class="snack-bar" [ngClass]="showSnackBar ? 'is-shown' : ''">
  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.54188 9.80367C4.80366 10.0654 5.24869 10.0654 5.51047 9.80367L13.2068 2.10733C13.4686 1.84555 13.4686 1.40052 13.2068 1.13874L12.2644 0.196335C12.0026 -0.065445 11.5838 -0.065445 11.322 0.196335L5.03927 6.47906L2.08115 3.54712C1.81937 3.28534 1.40052 3.28534 1.13874 3.54712L0.196335 4.48953C-0.065445 4.75131 -0.065445 5.19633 0.196335 5.45812L4.54188 9.80367Z" fill="#2C2C2C"/>
  </svg>
  <span>Profile Saved</span>
</div>

<div class="snack-bar snack-bar--error" [ngClass]="showErrorSnackBar ? 'is-shown' : ''">
  <svg width="10" height="10" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.8921 3.32357C21.504 2.71172 21.504 1.71971 20.8921 1.10786C20.2803 0.496005 19.2883 0.496004 18.6764 1.10786L11 8.78429L3.32357 1.10786C2.71172 0.496005 1.71971 0.496005 1.10786 1.10786C0.496005 1.71971 0.496004 2.71172 1.10786 3.32357L8.78429 11L1.10786 18.6764C0.496005 19.2883 0.496004 20.2803 1.10786 20.8921C1.71971 21.504 2.71172 21.504 3.32357 20.8921L11 13.2157L18.6764 20.8921C19.2883 21.504 20.2803 21.504 20.8921 20.8921C21.504 20.2803 21.504 19.2883 20.8921 18.6764L13.2157 11L20.8921 3.32357Z" fill="white"/>
  </svg>
  <span>Unable to save update. Please try again</span>
</div>

<div class="loading-overlay" *ngIf="showLoadingOverlay">
  <img src="https://angular.ncoa.org/assets/images/loader.gif" alt="Loading" />
</div>
