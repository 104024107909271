<ng-container *ngIf="accountLoginComponent">
  <div class="age-well-planner-sign-in">
    <ncoa-account-login
      [backCTA]="accountLoginComponent.backCTA"
      [eyebrow]="accountLoginComponent.eyebrow"
      [title]="accountLoginComponent.title"
      [desc]="accountLoginComponent.desc"
      [forgotPassword]="accountLoginComponent.forgotPassword"
      [signUp]="accountLoginComponent.signUp"
      (showErrorModal)="toggleErrorModal()"
      (showLoadingModal)="toggleLoadingModal()"
    ></ncoa-account-login>
    <div *ngIf="showErrorModal" class="age-well-planner-sign-in__error-modal">
      <div class="modal-container">
        <h2 class="title">{{ errorTitle }}</h2>
        <button type="button" class="dismiss-btn" (click)="toggleErrorModal()">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9145 3.36856C21.5175 2.75325 21.5175 1.76862 20.9145 1.1533C20.294 0.520155 19.2745 0.520154 18.6541 1.1533L11 8.96356L3.34595 1.1533C2.72547 0.520155 1.70596 0.520154 1.08548 1.1533C0.482471 1.76862 0.482471 2.75325 1.08548 3.36856L8.78429 11.2245L1.08548 19.0804C0.482471 19.6957 0.482471 20.6804 1.08548 21.2957C1.70596 21.9288 2.72547 21.9288 3.34595 21.2957L11 13.4854L18.6541 21.2957C19.2745 21.9288 20.294 21.9288 20.9145 21.2957C21.5175 20.6804 21.5175 19.6957 20.9145 19.0804L13.2157 11.2245L20.9145 3.36856Z" fill="white"/>
          </svg>
          Close
        </button>
        <p class="desc">
          {{ errorDesc }}
          <br /><br />
        </p>
        <a class="signin-cta" [href]="signInCTA.url" [title]="signInCTA.text">{{ signInCTA.text }}</a>
      </div>
    </div>
    <div *ngIf="showLoadingModal" class="age-well-planner-sign-in__loading-modal">
      <img src="https://angular.ncoa.org/assets/images/loader.gif" alt="Loading" />
    </div>
  </div>
