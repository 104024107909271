<div *ngIf="!data" class="loading-page">
  <img src="https://angular.ncoa.org/assets/images/loader.gif" alt="Loading" />
</div>

<age-well-planner *ngIf="data">
  <div class="vertical-topic-page">
    <ncoa-vertical-landing-header
      [title]="data.ncoaVerticalLandingHeader.title"
      [desc]="data.ncoaVerticalLandingHeader.desc"
      [image]="data.ncoaVerticalLandingHeader.image"
      [assessmentCTA]="data.ncoaVerticalLandingHeader.assessmentCTA"
      [menu]="data.ncoaVerticalLandingHeader.menu"
    ></ncoa-vertical-landing-header>
    <div id = "main-content">
      <ncoa-feature-content-block  *ngFor="let component of data.featureContentBlock?.blocks"
        [image]="component.image" [description]="component.description"
        [title]="component.title" [orientation]="component.orientation"
        [isButtonPresent]="component.isButtonPresent"
        [buttonCTAText]="component.buttonCTAText" [buttonCTALink]="component.buttonCTALink"
        [background]="component.backgroundColor"
      ></ncoa-feature-content-block>
      <ncoa-topic-listing
        *ngIf="data.ncoaTopicListing"
        [heading]="data.ncoaTopicListing.heading"
        [topics]="data.ncoaTopicListing.topics"
      ></ncoa-topic-listing>
      <ncoa-vertical-recommended
        *ngIf="data.recommended3ColGrid"
        [location]="data.recommended3ColGrid.location"
        [heading]="data.recommended3ColGrid.heading"
        [intro]="data.recommended3ColGrid.intro"
        [cards]="data.recommended3ColGrid.cards"
        [assessment]="data.recommended3ColGrid.assessment"
      ></ncoa-vertical-recommended>
      <ncoa-vertical-pathing
        *ngIf="data.verticalPathing"
        [heading]="data.verticalPathing.heading"
        [items]="data.verticalPathing.items"
      ></ncoa-vertical-pathing>
    </div>
  </div>
</age-well-planner>
