import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';

import { KontentDeliveryService } from './kontent-delivery.service';
import { ComponentService } from './component.service';
import { SeoService } from '@services/seo.service';
import { UrlService } from '@services/url.service';
import { DataLayerService } from '@services/data-layer.service';
import { TaxonomyService } from '@services/taxonomy.service';
import { ModalService } from '@services/modal.service';
import { GlobalService } from '@services/global.service';
import { environment } from '@environment';

import { FEEDBACK_URL, getItem, updateItem } from '@config/feedback-api';

// Add utc
dayjs.extend(utc);
dayjs.extend(timezone);

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  articleData: any = null;
  newsLetterBlock: any = null;
  callToDonateBlock: any = null;
  isInTools: boolean = false;

  constructor(
    private kontentDeliveryService: KontentDeliveryService,
    private urlService: UrlService,
    private global: GlobalService,
    private seoService: SeoService,
    private dataLayerService: DataLayerService,
    private componentService: ComponentService,
    private modalService: ModalService,
  ) { }

  getArticleData(slug) {
    return this.kontentDeliveryService.getItemAndCache(null, {
      'system.type': 'ncoa_article_content',
      'elements.url': encodeURIComponent(slug),
      'order': 'system.last_modified[desc]',
      'depth': 5,
      'limit': 1,
    }).then(rawAPIData => ({
      item: rawAPIData.items[0],
      modular_content: rawAPIData.modular_content,
    }));
  }

  getStaticBlocks() {
    return this.kontentDeliveryService.getItemAndCache(null, {
      'system.type[in]': 'newsletter_signup_block,call_to_donate',
      'order': 'system.last_modified[desc]',
      'depth': 5,
    });
  }

  getArticle(slug, isInTools = false) {
    this.isInTools = isInTools;

    return Promise.all([
      this.getArticleData(slug),
      this.getStaticBlocks(),
    ]).then(([
      rawAPIData,
      staticBlocks,
    ]: any[]) => {
      this.articleData = rawAPIData;
      
      this.newsLetterBlock = {
        items: [staticBlocks.items.find((item) => item.system.type === 'newsletter_signup_block')],
        modular_content: staticBlocks.modular_content,
      };

      this.callToDonateBlock = {
        items: [staticBlocks.items.find((item) => item.system.type === 'call_to_donate')],
        modular_content: staticBlocks.modular_content,
      };


      return Promise.all([
        this.getArticleVotes(rawAPIData.item.system.id),
        this.processRawArticleData(rawAPIData),
        this.processRawNewsletteSignUpBlockData(this.newsLetterBlock.items[0]),
        this.processRawPartnersGridData(rawAPIData),
        this.getRelatedArticles(rawAPIData.item),
        this.getFeaturedPageBlock(rawAPIData),
        this.processRawCallToDonateBlockData(this.callToDonateBlock),
        this.processMedicareLeadGen(rawAPIData),
      ])
    }).then(([
      articleVotes,
      articleData,
      newsletterSignUpBlockData,
      partnersGridData,
      relatedArticlesData,
      featuredPageBlockData,
      callToDonate,
      medicareLeadGen,
    ]: any[]) => {
      articleData['raw'] = this.articleData;

      if (partnersGridData) {
        articleData.components.push(partnersGridData);
      }
      articleData.components.push(
        {
          componentType: 'articleFeedback',
          votes: articleVotes,
          article: articleData.codename,
          slug: articleData.slug,
          id: articleData.id
        },
        { componentType: 'articleShareMobile', url: this.urlService.buildShareURLs() }
      );

      if (medicareLeadGen) {
        articleData.components.push({
          componentType: 'medicareLeadGen',
          ...medicareLeadGen,
        });
      }

      if (featuredPageBlockData) {
        articleData.components.push({
          componentType: 'featuredPageBlock',
          ...featuredPageBlockData,
        });
      }

      if (relatedArticlesData) {
        if (featuredPageBlockData) { // remove duplicate articles if shown in featured page block
          relatedArticlesData.entries = relatedArticlesData.entries.filter(article => { return featuredPageBlockData?.codeName !== article.codeName });
        }
        const max  = relatedArticlesData.entries.length > 5 ? 5 : relatedArticlesData.entries.length; // only show 5 related articles at most
        relatedArticlesData.entries = relatedArticlesData.entries.slice(0, max);
        articleData.components.push({ componentType: 'relatedArticles', ...relatedArticlesData });
      }

      switch (articleData.ending_module) {
        case 'call_to_donate': {
          articleData.components.push({
            componentType: 'callToDonate',
            data: callToDonate,
          });
          break;
        }
        default: {
          articleData.components.push(newsletterSignUpBlockData);
          break;
        }
      }

      return articleData;
    });
  }

  async processRawArticleData(rawAPIData) {
    const {
      display_date,
      title,
      body_ckeditor,
      author,
      secondary_authors,
      url,
      categories,
      audiences,
      primary_image,
      event_date_time,
      event_end_date___time,
      reading_time,
      pdf_viewer,
      registration_link,
      ending_module,
      upvote,
      downvote,
      sponsorship_indication_text,
      partnership_eyebrow,
      partnership_logo,
      system
    }: any = this.kontentDeliveryService.extractItemElements(rawAPIData.item);

    const components = [];
    const componentMap = new Map<string, any>();
    const [ category ] = categories;
    const [ audience ] = audiences;

    const authors = this.processByLineAuthors(author, rawAPIData.modular_content);

    this.modalService.showAvailableModal(audience?.codename, categories.map((cat) => cat.codename), system.codename);

    // partnership logo
    let partnership = null;
    if (partnership_logo.length > 0) {
      const partnershipCodeName = partnership_logo[0];
      const partnershipContent = rawAPIData.modular_content[partnershipCodeName];

      partnership = {
        eyebrow: partnership_eyebrow,
        url: partnershipContent.elements?.link.value,
        src: partnershipContent.elements.image.value[0].url,
        alt: partnershipContent.elements.image.value[0].description,
      };
    } else {
      partnership = {
        eyebrow: partnership_eyebrow,
        url: "",
        src: "",
        alt: "",
      };
    }

    // hero
    const heroData = event_date_time ?
    {
      componentType: 'eventHero',
      heroType: 'event',
      title,
      image: primary_image.length ? {
        responsive: this.global.remodelImageSrc(primary_image[0].url),
        url: primary_image[0].url,
        alt: primary_image[0].description,
        width : primary_image[0].width,
        height : primary_image[0].height,
      } : {},
      registration_link,
      ...this.buildEventData(event_date_time, event_end_date___time),
      partnership,
      sponsorship_indication_text,
    } :
    {
      componentType: 'articleHero',
      heroType: 'article',
      image: primary_image.length ? {
        responsive: this.global.remodelImageSrc(primary_image[0].url),
        url: primary_image[0].url,
        alt: primary_image[0].description,
        width : primary_image[0].width,
        height : primary_image[0].height,
      } : {},
      eyebrow: null, // To build below
      articleTitle: title,
      publishDate: !!display_date ? dayjs.utc( display_date ).format('MMM DD, YYYY') : '',
      readingTime: reading_time,
      sponsorship_indication_text:sponsorship_indication_text,
      partnership,
      authors: {
      componentType: 'authorByLine',
      variant: 'default',
      authors: authors.slice(0, 2)
      },
      takeawaysData: this.extractKeyTakeAwaysData(rawAPIData.item),
      url: this.urlService.buildShareURLs()
    };

    // Build eyebrow for hero
    if ( heroData.componentType === 'articleHero' ) {
      if (category?.codename && audience?.codename) {
        const categoryPage = await this.kontentDeliveryService.getItemAndCache(null, {
          'system.type': 'taxonomy_custom_content',
          'elements.categories[contains]': category.codename,
          'elements.audiences[contains]': audience.codename,
          'limit': 1,
        });

        if ((categoryPage?.items || []).length > 0) {
          heroData.eyebrow = {
            url: categoryPage.items[0].elements.category_page_url.value,
            text: `${category.name} for ${audience.name}`,
          };
        } else {
          heroData.eyebrow = {
            url: null,
            text: `${category.name} for ${audience.name}`,
          };
        }
      } else if (!category?.codename && audience?.codename) {
        const audiencePage = await this.kontentDeliveryService.getItemAndCache(null, {
          'system.type': 'template___audience_page',
          'elements.audiences[contains]': audience.codename,
          'limit': 1,
        });

        if ((audiencePage?.items || []).length > 0) {
          heroData.eyebrow = {
            url: audiencePage.items[0].elements.audience_page_url.value,
            text: audience.name,
          };
        } else {
          heroData.eyebrow = {
            url: null,
            text: audience.name,
          };
        }
      } else {
        if ( category?.name ) {
          heroData.eyebrow = {
            url: null,
            text: category.name,
          };
        }
        if ( audience?.name ) {
          heroData.eyebrow = {
            url: null,
            text: `${heroData?.eyebrow?.text || ''}${(category?.name ? ' for ' : '')}${audience.name}`,
          };
        }
      }
    }

    components.push(heroData);

    // Add authorByLineData
    components.push({
      componentType: 'authorByLine',
      variant: 'default',
      authors: authors.slice(0, 2)
    });

    // key takeaways
    const takeawaysData: any = this.extractKeyTakeAwaysData(rawAPIData.item);
    if ( takeawaysData ) {
      takeawaysData.componentType = 'takeaways';
    }


    // custom style config
    const styleConfig = {
      alignment: rawAPIData.item.elements.script_alignment,
      position: rawAPIData.item.elements.script_placement
    }
    const requiredFieldError = rawAPIData.item.elements?.required_field_error?.value;

    // body
    const bodyData = {
      content: body_ckeditor || '',
      readingTime: reading_time,
      url: this.urlService.buildShareURLs()
    };

    if ( bodyData ) {
      components.push({
        componentType: 'body',
        ...bodyData,
        styleConfig,
        requiredFieldError

      })
    }

    // pdf viewer
    if ( pdf_viewer ) {
      components.push({
        componentType: 'pdfViewer',
        pdfData: `/assets/viewer/web/viewer.html?url=${pdf_viewer}`
      })
    }

    // secondary author block
    if ( secondary_authors?.length ) {
      components.push({
        componentType: 'secondaryAuthorByLine',
        variant: 'others',
        authors: this.processByLineAuthors(secondary_authors, rawAPIData.modular_content)
      });
    }

    // seo
    this.seoData(rawAPIData.item);

    // analytics
    this.dataLayerService.push({
      url: location.href,
      pageTitle: 'title' in heroData ? heroData.title : heroData.articleTitle,
      contentType: 'article',
      category: category?.codename || '',
      audience: audience?.codename || ''
    },!this.isInTools);

    return {
      codename: rawAPIData.item.system.codename,
      id: rawAPIData.item.system.id,
      slug: url,
      components,
      categories,
      audiences,
      takeaways: takeawaysData,
      upvotePercent: 100 * upvote / (upvote + downvote),
      ending_module: ending_module.length > 0 ? ending_module[0].codename || 'newsletter' : 'newsletter',
    }
  }

  getArticleVotes(itemID) {
    return getItem(itemID)
      .then(({ upvote, downvote }: any) => {
        const totalVotes = upvote + downvote;
        return Math.round(100 * upvote / (totalVotes ? totalVotes : 1));
      });
  }

  processByLineAuthors(rawAuthors, modular_content) {
    return rawAuthors.map(authorCodename => {
      const authorRawData = this.kontentDeliveryService.extractModularContent({ modular_content }, authorCodename);
      const {
        author_name,
        author_headshot,
        author_position,
        author_url_slug
      }: any = this.kontentDeliveryService.extractItemElements(authorRawData);

      return {
        photo: author_headshot[0]?.url || '',
        name: author_name,
        link: this.urlService.buildAuthorLink(author_url_slug),
        position: author_position
      }
    });
  }

  buildEventData(start_datetime, end_datetime) {
    const startDateObj = new Date (start_datetime);
    const startDate = start_datetime.split('T')[0];
    const startTime = startDateObj.toLocaleTimeString('en-US', {hour12: false});

    // Follow format from Kentico YYYY-MM-DDThh:mm
    const startDateTime = {
      year: parseInt(startDate.split('-')[0]),
      month: parseInt(startDate.split('-')[1]) - 1,
      day: parseInt(startDate.split('-')[2]),
      hours: parseInt(startTime.split(':')[0]),
      minutes: parseInt(startTime.split(':')[1]),
    };

    let endDateTime = {
      year: parseInt(startDate.split('-')[0]),
      month: parseInt(startDate.split('-')[1]) - 1,
      day: parseInt(startDate.split('-')[2]),
      hours: 23,
      minutes: 59,
    };

    if (end_datetime) {
      const endDate = end_datetime.split('T')[0];
      const endDateObj = new Date (end_datetime);
      const endTime = endDateObj.toLocaleTimeString('en-US', {hour12: false});

      endDateTime = {
        year: parseInt(endDate.split('-')[0]),
        month: parseInt(endDate.split('-')[1]) - 1,
        day: parseInt(endDate.split('-')[2]),
        hours: parseInt(endTime.split(':')[0]),
        minutes: parseInt(endTime.split(':')[1]),
      };

    }
     
    return {
      start: startDateTime,
      end: endDateTime,
      disregardEndTime: !end_datetime,
      isPast: end_datetime ? this.isDatePast(new Date(end_datetime)): this.isDatePast(new Date(start_datetime).setHours(23,59)),
    };
  }

  isDatePast(end) {
    return  Date.now() > end;
  }

  sendArticleVote(itemID, voteType, revote = false) {
    return updateItem(itemID, voteType, revote)
    .then(({ upvote, downvote }: any) => {
      const totalVotes = upvote + downvote;
      return Math.round(100 * upvote / (totalVotes ? totalVotes : 1));
    });
  }

  extractKeyTakeAwaysData(rawData) {
    const {
      article_key_takeaways__subheadline,
      article_key_takeaways__key_takeaway_1,
      article_key_takeaways__key_takeaway_2,
      article_key_takeaways__key_takeaway_3
    }: any = this.kontentDeliveryService.extractItemElements(rawData, [
      'article_key_takeaways__subheadline',
      'article_key_takeaways__key_takeaway_1',
      'article_key_takeaways__key_takeaway_2',
      'article_key_takeaways__key_takeaway_3'
    ]);

    const takeaways = [
      article_key_takeaways__key_takeaway_1,
      article_key_takeaways__key_takeaway_2,
      article_key_takeaways__key_takeaway_3
    ].filter(content => content ).map(text => ({ text }));

    return {
      heading: article_key_takeaways__subheadline,
      takeaways
    };
  }

  seoData(rawAPIData) {
    if ( !rawAPIData ) {
      return;
    }

    const defaultDesc = `${this.getDefaultDescription(rawAPIData.elements.body_ckeditor.value)}...`;
    const placeholderImage = `${window.location.origin}/assets/images/article-hero-share-coral-desktop.png`;

    const seoData = {
      title: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__meta_title.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__meta_title.value
        : rawAPIData.elements.title.value,
      description: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__meta_description.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__meta_description.value
        : defaultDesc,
      image: rawAPIData.elements.primary_image.value.length > 0
        ? rawAPIData.elements.primary_image.value[0].url
        : placeholderImage,
      ogTitle: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_title.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_title.value
        : rawAPIData.elements.title.value,
      ogDescription: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_description.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_description.value
        : defaultDesc,
      ogImage: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_image.value.length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__facebook_og_image.value[0].url
        : rawAPIData.elements.primary_image.value.length > 0
          ? rawAPIData.elements.primary_image.value[0].url
          : placeholderImage,
      ogType: 'article',
      twitterTitle: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_title.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_title.value
        : rawAPIData.elements.title.value,
      twitterDescription: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_description.value.trim().length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_description.value
        : defaultDesc,
      twitterImage: rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_image.value.length > 0
        ? rawAPIData.elements.seo_metadata_example_to_include_in_any_type__twitter_card_image.value[0].url
        : rawAPIData.elements.primary_image.value.length > 0
          ? rawAPIData.elements.primary_image.value[0].url
          : placeholderImage,
    };

    this.seoService.applyMetaData(seoData);
  }

  getDefaultDescription(body) {
    const div = document.createElement('div');
    div.innerHTML = body;
    const p = div.querySelector('p');

    if (p) {
      const content = p?.textContent || '';
      return content.substring(0, 120);
    } else {
      const content = div?.textContent || '';
      return content.substring(0, 120);
    }
  }

  processRawNewsletteSignUpBlockData(rawNewsletterSignUpBlockData) {
    return this.componentService.processNewsletterSignupComponent(rawNewsletterSignUpBlockData);
  }

  processRawPartnersGridData(rawAPIData) {
    const { partner_grid }: any = this.kontentDeliveryService.extractItemElements(rawAPIData.item, [ 'partner_grid' ]);

    if (partner_grid) {
      const partnerGrid = rawAPIData.modular_content[partner_grid];

      if (partnerGrid) {
        const title = partnerGrid.elements.title.value;
        let partners = partnerGrid.elements.grid_items.value.map((codename) => rawAPIData.modular_content[codename]);
            partners = partners.map((partner) => {
              return {
                name: partner.elements.name.value,
                logo: partner.elements.image.value.length > 0
                  ? partner.elements.image.value[0].url
                  : null,
                alt: partner.elements.image.value.length > 0
                  ? partner.elements.image.value[0].description
                  : null,
              };
            });

        return {
          componentType: 'partners_grid',
          heading: title,
          partners,
        };
      }
    }
  }

  getRelatedArticles(rawAPIData) {
    const primaryCategory = rawAPIData.elements.categories.value[0];
    const secondaryCategory = rawAPIData.elements.secondary_categories.value[0];
    const category = primaryCategory || secondaryCategory;

    const primaryAudience = rawAPIData.elements.audiences.value[0];
    const secondaryAudience = rawAPIData.elements.secondary_audiences.value[0];
    const audience = primaryAudience || secondaryAudience;

    if (category || audience) {
      const primaryParams = {
        'system.type': 'ncoa_article_content',
        'elements.url[neq]': rawAPIData.elements.url.value,
        'elements.display_date[nempty]': true,
        'order': 'elements.display_date[desc]',
        'limit': 6,
      };

      const secondaryParams = {
        'system.type': 'ncoa_article_content',
        'elements.url[neq]': rawAPIData.elements.url.value,
        'elements.display_date[nempty]': true,
        'order': 'elements.display_date[desc]',
        'limit': 6,
      };

      if (category) {
        primaryParams['elements.categories[contains]'] = category.codename;
        secondaryParams['elements.secondary_categories[contains]'] = category.codename;
      }

      if (audience) {
        primaryParams['elements.audiences[contains]'] = audience.codename;
        secondaryParams['elements.secondary_audiences[contains]'] = audience.codename;
      }

      const primaryArticles = this.kontentDeliveryService.getItemAndCache(null, primaryParams);
      const secondaryArticles = this.kontentDeliveryService.getItemAndCache(null, secondaryParams);

      return Promise.all([
        primaryArticles,
        secondaryArticles,
      ]).then(([
        primaryArticlesData,
        secondaryArticlesData,
      ]) => {
        let articles = [...primaryArticlesData.items, ...secondaryArticlesData.items];

        articles = articles.map((article) => ({ ...article, date: new Date(article.elements.display_date.value) }));
        articles = articles.sort((a, b) => b.date - a.date);
        // reduce articles to remove dupes due to duplicative tagging 
        articles = [...new Map(articles.map(item => [item.system.id, item])).values()]

        articles = articles.map((article) => {
          const title = article.elements.title.value;
          const publishDate = dayjs.utc( article.elements.display_date.value ).format('MMM DD, YYYY');
          const ctaUrl = `/article/${article.elements.url.value}`;
          const image = article.elements.primary_image.value.length > 0 ? {
            responsive: this.global.remodelImageSrc(article.elements.primary_image.value[0].url, 'relatedArticles'),
            url: article.elements.primary_image.value[0].url,
            alt: article.elements.primary_image.value[0].description
          }: {};
          const codeName = article.system.codename;
          const type = article.elements.event_date_time.value ? 'event' : 'article';

          return {
            title,
            publishDate,
            ctaUrl,
            image,
            codeName,
            type
          };
        });

        return { entries: articles };
      });
    }

    return null;
  }

  getFeaturedPageBlock(rawAPIData) {
    const featuredPageBlock = rawAPIData.modular_content[rawAPIData.item.elements.featured_page_block.value[0]];

    if (featuredPageBlock) {
      const backgroundColor = featuredPageBlock.elements.background_color.value.length > 0
        ? featuredPageBlock.elements.background_color.value[0].codename
        : 'green';
      const orientation = backgroundColor === 'blue' ? 'image-text' : 'text-image';
      const manualLink = featuredPageBlock.elements.manual_link.value;

      let type = null;
      let url = null;
      let headline = null;
      let description = null;
      let image = null;
      let codeName = null;
      let blockCTA = featuredPageBlock.elements.feature_block_custom_cta?.value?.length > 0 ? featuredPageBlock.elements.feature_block_custom_cta.value : null;

      let internalLinkCodeName = featuredPageBlock.elements.internal_link.value;
          internalLinkCodeName = internalLinkCodeName.length > 0 ? internalLinkCodeName[0] : null;

      if (internalLinkCodeName) {
        const linkedPage = rawAPIData.modular_content[internalLinkCodeName];

        if (linkedPage) {
          switch (linkedPage.system.type) {
            case 'ncoa_article_content': {
              headline = linkedPage.elements.title.value;
              description = linkedPage.elements.seo_metadata_example_to_include_in_any_type__meta_description.value;
              image = linkedPage.elements.primary_image.value.length > 0
                ? {
                    url: linkedPage.elements.primary_image.value[0].url,
                    alt: linkedPage.elements.primary_image.value[0].description,
                    responsive: this.global.remodelImageSrc(linkedPage.elements.primary_image.value[0].url),
                  }
                : null;
              type = linkedPage.elements.event_date_time.value ? 'event' : 'article';
              url = this.urlService.buildArticleURL(linkedPage.elements.url.value);
              codeName = linkedPage.system.codename;
              break;
            }
            case 'standard_page': {
              headline = linkedPage.elements.title.value;
              description = linkedPage.elements.description.value;
              image = linkedPage.elements.primary_image.value.length > 0
                  ? {
                      url: linkedPage.elements.primary_image.value[0].url,
                      alt: linkedPage.elements.primary_image.value[0].description,
                      responsive: this.global.remodelImageSrc(linkedPage.elements.primary_image.value[0].url),
                    }
                  : null;
              type = 'page';

              const parentPageCodename = linkedPage.elements.parent_page.value.length > 0
                ? linkedPage.elements.parent_page.value[0]
                : null;

              if (parentPageCodename) {
                const parentPage = rawAPIData.modular_content[parentPageCodename];

                if (parentPage) {
                  url = `/page/${parentPage.elements.url.value}/${linkedPage.elements.url.value}`;
                } else {
                  url = `/page/${linkedPage.elements.url.value}`;
                }
              } else {
                url = `/page/${linkedPage.elements.url.value}`;
              }
              break;
            }
            case 'taxonomy_custom_content': {
              headline = linkedPage.system.name;
              description = linkedPage.elements.taxonomy_description.value;
              image = linkedPage.elements.header_image.value.length > 0
               ? {
                   url: linkedPage.elements.header_image.value[0].url,
                   alt: linkedPage.elements.header_image.value[0].description,
                   responsive: this.global.remodelImageSrc(linkedPage.elements.header_image.value[0].url),
                 }
               : null;
              type = 'page';
              url = `/${linkedPage.elements.category_page_url.value}`;

              break;
            }
          }
        }
      }

      headline = featuredPageBlock.elements.title.value;
      description = featuredPageBlock.elements.pathing_description.value;
      image = featuredPageBlock.elements.image_asset.value.length > 0 ? {
        url: featuredPageBlock.elements.image_asset.value[0].url,
        alt: featuredPageBlock.elements.image_asset.value[0].description,
        responsive: this.global.remodelImageSrc(featuredPageBlock.elements.image_asset.value[0].url),
      } : image;
      type = 'page';
      url = url || manualLink;

      return {
        headline,
        description,
        type,
        image,
        background: backgroundColor,
        url,
        orientation,
        codeName,
        blockCTA
      };
    }

    return null;
  }

  processRawCallToDonateBlockData(rawCallToDonateBlockData) {
    return this.componentService.processCallToDonateComponent(rawCallToDonateBlockData?.items ? rawCallToDonateBlockData.items[0] : null);
  }

  processMedicareLeadGen(rawAPIData) {
    const { item, modular_content } = rawAPIData;
    const { elements } = item;

    if (elements) {
      // medicare lead gen
      if (elements.medicare_lead_gen.value.length > 0) {
        const leadGenCodeName = elements.medicare_lead_gen.value[0];
        const contactCodeName = modular_content[leadGenCodeName].elements.medicare_contact_landing_page.value[0];
        const { elements: medicareLeadGenElements } = modular_content[leadGenCodeName];
        const { elements: medicareContactElements } = modular_content[contactCodeName];

        const getValue = Object.entries({...medicareContactElements, ...medicareLeadGenElements}).reduce((acc, [key, value]) => {
          acc[key] = value['value'];
          return acc;
        }, {});

        const {
          eyebrow,
          headline,
          left_image,
          branding_image,
          form_instructions,
          form_required_text,
          form_optional_label,
          form_tooltip_label,
          first_name_label,
          first_name_required_error,
          interest_reason_label,
          last_name_label,
          last_name_required_error,
          email_label,
          email_required_error,
          email_invalid_error,
          zip_code_label,
          zip_code_required_error,
          zip_code_invalid_error,
          zip_code_tooltip_text,
          phone_number_label,
          phone_number_invalid_error,
          phone_number_tooltip_text,
          date_of_birth_label,
          date_of_birth_required_error,
          date_of_birth_invalid_error,
          date_of_birth_tooltip_text,
          form_cta_text,
          form_agreement_text,
        }: any = getValue;

        const form = {
          form_instructions,
          form_required_text,
          form_optional_label,
          form_tooltip_label,
          first_name_label,
          first_name_required_error,
          interest_reason_label,
          last_name_label,
          last_name_required_error,
          email_label,
          email_required_error,
          email_invalid_error,
          zip_code_label,
          zip_code_required_error,
          zip_code_invalid_error,
          zip_code_tooltip_text,
          phone_number_label,
          phone_number_invalid_error,
          phone_number_tooltip_text,
          date_of_birth_label,
          date_of_birth_required_error,
          date_of_birth_invalid_error,
          date_of_birth_tooltip_text,
          form_cta_text,
          form_agreement_text,
        };

        let image: object|null = null;
        if (left_image.length > 0) {
          image = {
            sizes: this.global.remodelImageSrc(left_image[0].url),
            src: left_image[0].url,
            alt: left_image[0].description,
          }
        }

        let branding: object|null = null;
        if (branding_image.length > 0) {
          branding = {
            src: branding_image[0].url,
            alt: branding_image[0].description,
          }
        }

        return {
          eyebrow,
          headline,
          image,
          branding,
          form,
        }
      }

      return null;
    }

    return null;
  }
}
