<age-well-planner *ngIf="!isLoading">
  <ncoa-saved-to-planner-header
    [selectedType]="selectedType"
    [selectedVertical]="selectedVertical"
    (updateSelectedType)="updateSelectedType($event)"
    (updateSelectedVertical)="updateSelectedVertical($event)"
  ></ncoa-saved-to-planner-header>

  <ncoa-saved-to-planner-listing
    [selectedType]="selectedType"
    [selectedVertical]="selectedVertical"
  ></ncoa-saved-to-planner-listing>
</age-well-planner>

<div *ngIf="isLoading" class="loading-page">
  <img src="https://angular.ncoa.org/assets/images/loader.gif" alt="Loading" />
</div>
