<div class="saved-to-planner-listing">
  <div class="saved-to-planner-listing__wrapper">
    <h2 class="counter" role="status">Showing {{ displayedCards.length }} Saved Items</h2>
    <div class="loading-state" *ngIf="isLoading">
      <img src="https://angular.ncoa.org/assets/images/loader.gif" alt="Loading" />
    </div>
    <div *ngIf="!isLoading">
      <div class="empty-state" *ngIf="displayedCards.length === 0">
        <p>
          Start saving items using
          <span>
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 2.23226V17.2147C1.5 18.2429 2.68505 18.8189 3.49357 18.1836L9 13.8571L14.5064 18.1836C15.3149 18.8189 16.5 18.2429 16.5 17.2147V2.23226C16.5 1.5517 15.9483 1 15.2677 1H2.73226C2.0517 1 1.5 1.5517 1.5 2.23226Z" stroke="#0B4A5D" stroke-width="2"/>
            </svg>
            <span>Save</span>
          </span>
          button
        </p>
      </div>
      <ul class="listing" *ngIf="displayedCards.length > 0">
        <li class="listing__item" *ngFor="let card of displayedCards">
          <ncoa-planner-card
            [id]="card.id"
            [linkedID]="card.linkedID"
            [linkedCodename]="card.linkedCodename"
            [type]="card.type"
            [eyebrow]="card.eyebrow"
            [heading]="card.heading"
            [intro]="card.intro"
            [cta]="card.cta"
            [bookmark]="card.bookmark"
            [isSaved]="card.isSaved"
            [breadcrumb]="card.breadcrumb"
            [photo]="card.photo"
            [variation]="card.variation"
            [theme]="card.theme"
          ></ncoa-planner-card>
        </li>
      </ul>
      <div class="load-more" *ngIf="filteredCards.length !== 0 && filteredCards.length !== displayedCards.length">
        <button
          *ngIf="!isLoadingMore"
          type="button"
          class="load-more__btn"
          (click)="loadMore()"
        >
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0Z" fill="#0B4A5D"/>
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="12" y="10" width="16" height="20">
            <path d="M12.5 10.5H27.0556V29.5H12.5V10.5Z" fill="white" stroke="#0B4A5D"/>
            </mask>
            <g mask="url(#mask0)">
            <path d="M20.1772 29.3267L20.1765 29.3274C20.0535 29.4481 19.9103 29.5 19.7759 29.5C19.6416 29.5 19.4984 29.4481 19.3754 29.3274L19.3747 29.3267L12.672 22.7747C12.6719 22.7746 12.6718 22.7745 12.6717 22.7745C12.4427 22.5494 12.4428 22.2134 12.672 21.9884C12.9064 21.7584 13.265 21.7582 13.4995 21.988C13.4996 21.9882 13.4998 21.9883 13.4999 21.9884L18.3522 26.7769L19.2034 27.617V26.4211V11.0526C19.2034 10.7586 19.4533 10.5 19.7759 10.5C20.0986 10.5 20.3485 10.7586 20.3485 11.0526V26.4211V27.6078L21.1977 26.7789L26.0778 22.0157L26.0788 22.0147C26.3133 21.7846 26.6722 21.7845 26.9071 22.0149C27.1009 22.2052 27.118 22.5409 26.8803 22.7743C26.8802 22.7744 26.8801 22.7745 26.88 22.7746L20.1772 29.3267Z" fill="white" stroke="white"/>
            </g>
          </svg>
          <div>Load More Resources</div>
        </button>
        <img *ngIf="isLoadingMore" src="https://angular.ncoa.org/assets/images/loader.gif" alt="Loading" />
      </div>
    </div>
  </div>
</div>
