<age-well-planner-assessment>
  <div class="planner-contact-form">
    <div class="planner-contact-form__wrapper">
      <div class="exit-container">
        <a class="back-cta" (click) = 'goBack($event)'>
          <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M6.86842 0.547222L0.315788 7.25062C0.105263 7.46513 0 7.73326 0 8.0014C0 8.26954 0.105263 8.53767 0.315788 8.75218L6.86842 15.4556C7.28947 15.8846 7.94737 15.8846 8.36842 15.4556C8.78947 15.0266 8.78947 14.3562 8.36842 13.9272L3.57895 9.07395L18.9474 9.07395C19.5263 9.07395 20 8.5913 20 8.0014C20 7.4115 19.5263 6.92886 18.9474 6.92886L3.57895 6.92886L8.34211 2.04878C8.76316 1.61977 8.76316 0.949426 8.34211 0.52014C7.94737 0.117936 7.28947 0.117936 6.86842 0.547222Z"
              fill="#0B4A5D" />
          </svg>
          <span>{{label.exit}}</span>
        </a>
      </div>
      <div class="main-container"  id = 'main-content'>
        <span class="header-title">{{label.headerTitle}}</span>
        <h3 class="instruction-title">{{label.insTitle}}</h3>
        <p class="instruction-subtitle">{{label.insSubtitle}}</p>
        <label *ngIf="!isSuccessfullySent" for=""
          [ngClass]="{'_label': true, '_label-email': true}" id = 'email-label'>{{label.email}}</label>
        <input *ngIf="!isSuccessfullySent" type="email"
          [ngClass]="{'_input': true, '_input-email': true, 'invalid': email.status.code === 'error'}"
          [(ngModel)]='email.value'
          aria-labelledby="email-label">

        <div class = "sr-only" *ngIf= 'isSuccessfullySent' role = 'alert'>{{label.successInsTitle}}</div>
        <p *ngIf="!isSuccessfullySent && email.status.code === 'error'" role = 'alert' class="invalid-message">{{email.status.message}}
        </p>
        <div *ngIf="!isSuccessfullySent" class="dropdown-container">
          <select class="_input _input-state" required [(ngModel)]="selectedState" aria-label = 'Select State' (change)='onStateChange()'>
            <option value="" aria-hidden="true" disabled selected hidden class="_placeholder">{{label.selectState}}</option>
            <option [value]="stateOption.value" *ngFor="let stateOption of stateOptions">{{ stateOption.text }}</option>
          </select>
          <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M7.67406 7.70909C7.48101 7.90303 7.23967 8 6.99835 8C6.75703 8 6.51571 7.90303 6.32265 7.70909L0.289587 1.67273C-0.096529 1.28485 -0.096529 0.678788 0.289587 0.290909C0.675703 -0.0969697 1.27901 -0.0969697 1.66513 0.290909L6.99835 5.82562L12.3557 0.315152C12.7418 -0.0727268 13.3451 -0.0727268 13.7315 0.315152C14.0935 0.678788 14.0935 1.28485 13.7071 1.67273L7.67406 7.70909Z"
              fill="#0B4A5D" />
          </svg>
        </div>
        <label *ngIf="!isSuccessfullySent" class="_label _label-comment" id = 'comment-label'>{{label.comment}}</label>
        <textarea *ngIf="!isSuccessfullySent" row="3" aria-labelledby = 'comment-label' class="_input _input-comment"
          [(ngModel)]='comment.value'></textarea>

        <button *ngIf="!isSuccessfullySent" class="btn-submit" (click)='onSubmitClick()'>{{label.submit}}</button>

        <div class="loading-overlay" *ngIf="showLoadingOverlay">
          <img src="https://angular.ncoa.org/assets/images/loader.gif" alt="Loading" />
        </div>
      </div>
    </div>
  </div>
</age-well-planner-assessment>
